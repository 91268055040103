import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Spin, Image } from 'antd';
import { useParams } from 'react-router-dom';
import { handleStudent } from '@services';
import dataCity from '@configs/dataCity.json';
import moment from 'moment';
import StudentForm from '@components/Form/StudentForm';
import { breadcrumbDetailStudentTeacher as breadcrumbItems } from '@components/Breadcrumb';
import '../index.scss';

const DetailStudentTeacher = () => {
  const [initialValues, setInitialValues] = useState<any>();
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [wards, setWards] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl1, setImageUrl1] = useState<string>();
  const [imageUrl2, setImageUrl2] = useState<string>();
  const [imageUrl3, setImageUrl3] = useState<string>();

  const title = 'Thông tin học viên';
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      handleStudent
        .GetDetailt(id)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setData(data);
            setIsLoading(false);
          } else if (statusCode === 403) {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, []);
  useEffect(() => {
    if (data) {
      const dobDate = new Date(data.DOB);
      const identificationDate = new Date(data.IdentificationDate);
      const initValues = {
        userName: data.userName,
        name: data.name,
        identificationNumber: data.identificationNumber,
        IdentificationDate: moment.utc(identificationDate),
        IdentificationAdress: data.identificationAdress,
        DOB: moment.utc(dobDate),
        gender: data.gender,
        city: data.city,
        district: data.district,
        wards: data.wards,
        street: data.street,
        phone: data.phone,
        email: data.email,
        parentPhone: data.parentPhone,
        wishToJoin: data.wishToJoin,
        note: data.note,
      };
      setInitialValues(initValues);
      setImageUrl(data.avatar);
      if (data.identificationImage) {
        setImageUrl1(data.identificationImage[0]);
        setImageUrl2(data.identificationImage[1]);
      }
      setImageUrl3(data.isSuspended);
      handleCityChange(data.city);
      handleDistrictChange(data.district);
      const city = cities.find((city) => city.Id === data.city);
      if (city) {
        const district = city.Districts.find(
          (district) => district.Id === data.district,
        );
        if (district) {
          setWards(district.Wards);
        }
      }
    }
  }, [data]);
  useEffect(() => {
    setCities(dataCity);
  }, []);

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedDistrict('');
    setWards([]);
    const city = cities.find((city) => city.Id === value);
    if (city) {
      setDistricts(city.Districts);
    }
  };
  const handleDistrictChange = (value) => {
    setSelectedDistrict(value);
    const city = cities.find((city) => city.Id === selectedCity);
    if (city) {
      const district = city.Districts.find((district) => district.Id === value);
      if (district) {
        setWards(district.Wards);
      }
    }
  };

  const onFinish = (values: any) => {
    console.log(values, 'values');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleOpen = () => {
    console.log('oke');
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <div className="main main-add">
          <div className="main-left">
            {initialValues && (
              <StudentForm
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                selectedCity={selectedCity}
                handleCityChange={handleCityChange}
                cities={cities}
                selectedDistrict={selectedDistrict}
                handleDistrictChange={handleDistrictChange}
                districts={districts}
                wards={wards}
                isDetail={false}
                isDisabled={true}
                handleOpen={handleOpen}
              />
            )}
          </div>
          <div className="main-right">
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">Ảnh chân dung 3x4</h4>

              {imageUrl ? (
                <Image
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">Ảnh 2 mặt CCCD</h4>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {imageUrl1 ? (
                  <Image
                    src={imageUrl1}
                    alt="avatar"
                    style={{ height: '260px' }}
                  />
                ) : (
                  <></>
                )}

                {imageUrl2 ? (
                  <Image
                    src={imageUrl2}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                Ảnh biên bản đình chỉ học tập
              </h4>
              {imageUrl3 ? (
                <Image src={imageUrl3} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailStudentTeacher;
