import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, notification, Spin } from 'antd';
import { handleClass, handleStudent } from '@services';
import { OPageIndex } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import TabelComponent from '@components/Table/TableCheckBox';
import FilterComponent from '@components/Filter';
import { breadcrumbAddStudentClass as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsAddStudentClass as columns } from '@components/Table/Columns';
import { dataOptionAddStudentClass as dataOption } from '@components/Filter/dataOption';
import '../../index.scss';

const AddStudentToClass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [dataClass, setDataClass] = useState<any>();
  const [title, setTitle] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const titleButton = '';
  const routerAdd = '';

  const showModal = () => {
    setOpen(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    getDataTable();

    handleClass
      .detailClass(code)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setDataClass(data);
          setTitle(data.name);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  }, [location.search]);
  useEffect(() => {
    const myisActive = queryParams.get('isActive');
    if (myisActive) {
      if (myisActive === 'true') {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
      } else {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
      }
    }
  }, []);
  const getDataTable = () => {
    const queryParams = new URLSearchParams(location.search);
    const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    setTableLoading(true);
    handleStudent
      .GetListOutClass(mypageIndex, myisActive, myText)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data.listData);
          setCurrentPage(Number(data.pageIndex));
          setTotalItems(Number(data.totalRecord));
          setTableLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const showModalExcel = () => {
    setOpenExcel(true);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const hideModalExcel = () => {
    setOpenExcel(false);
  };

  const handleDownloadExcel = async () => {
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    handleStudent.downloadExcelStudentNoClass(myisActive, myText);
    setOpenExcel(false);
  };

  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmitFilter = () => {
    setIsOpen(!isOpen);
    const params = new URLSearchParams(window.location.search);
    if (select2Value == 'true') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái đang hoạt động',
          field: 'isActive',
          value: true,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'true');
      } else {
        params.append('isActive', 'true');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    } else if (select2Value == 'false') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái không hoạt động',
          field: 'isActive',
          value: false,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'false');
      } else {
        params.append('isActive', 'false');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    }
  };

  const handleChangeInput = (value) => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('text')) {
      params.set('text', value);
    } else {
      params.append('text', value);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleDeleteFilter = (key) => {
    const updatedDataFilter = dataFilter.filter((item) => item.key !== key);
    setDataFilter(updatedDataFilter);
    const params = new URLSearchParams(window.location.search);
    if (params.has('isActive')) {
      params.delete('isActive');
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} học viên</span>
    );
  };
  const handleAddStudentToClass = () => {
    const body = {
      listUsers: selectedRowKeys,
      class: dataClass.code,
    };
    handleClass
      .addStudentToClass(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công thêm tài khoản học viên vào lớp',
          });
          setOpen(false);
          getDataTable();
          setSelectedRowKeys([]);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Xảy ra lỗi trong quá trình xử lý dữ liệu',
          });
          setIsLoading(false);
          setSelectedRowKeys([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setSelectedRowKeys([]);
      });
  };
  return (
    <Spin tip="Đang xử lý dữ liệu ..." spinning={isLoading} size="large">
      <Modal
        title="Thêm học viên vào lớp"
        open={open}
        onOk={() => handleAddStudentToClass()}
        onCancel={() => hideModal()}
        footer={
          selectedRowKeys?.length > 0 ? (
            <div>
              {/* Nội dung của footer */}
              <Button onClick={() => handleAddStudentToClass()} type="primary">
                Xác nhận
              </Button>
              <Button onClick={() => hideModal()}>Hủy bỏ</Button>
            </div>
          ) : null
        }
      >
        {selectedRowKeys && (
          <>
            {selectedRowKeys?.length > 0 ? (
              <p>
                Bạn đồng ý thêm <strong>{selectedRowKeys?.length}</strong> học
                viên vào lớp <strong>{title}</strong>
              </p>
            ) : (
              <p>Xin lỗi, bạn phải chọn học viên để thêm vào lớp</p>
            )}
          </>
        )}
      </Modal>
      <Modal
        title="Tải dữ liệu lớp học"
        open={openExcel}
        onOk={() => handleDownloadExcel()}
        onCancel={() => hideModalExcel()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận tải xuống dữ liệu <strong>{totalItems} lớp học</strong> ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={`Thêm học viên vào lớp ` + title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterComponent
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        setText={setText}
        text={text}
        handleChangeInput={handleChangeInput}
        showModalExcel={showModalExcel}
        dataFilter={dataFilter}
        handleDeleteFilter={handleDeleteFilter}
        handleSelect1Change={handleSelect1Change}
        select1Value={select1Value}
        searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
        dataOption={dataOption}
        select2Value={select2Value}
        setSelect2Value={setSelect2Value}
        handleSubmitFilter={handleSubmitFilter}
        placeholderInput={
          'Tìm kiếm theo mã học viên, tên học viên, số điện thoại học viên'
        }
      />

      <div className="table">
        <TabelComponent
          columns={columns({})}
          data={data}
          tableLoading={tableLoading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalItems={totalItems}
          customTotal={customTotal}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }}
        />
        <div
          style={{ display: 'flex', justifyContent: 'right', marginBottom: 30 }}
        >
          <Button type="primary" onClick={() => showModal()}>
            Thêm {selectedRowKeys?.length} học viên vào lớp
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddStudentToClass;
