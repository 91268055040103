import { axiosInstanceAuthorization } from '../Config/AxiosInterceptor';

const getTotal = (startDate: string, endDate: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL +
      `/api/v1/statistical/total?startDate=${startDate}&endDate=${endDate}`,
  );
};
const getDataChart = (startDate: string, endDate: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL +
      `/api/v1/statistical/chart?startDate=${startDate}&endDate=${endDate}`,
  );
};
export default {
  getTotal,
  getDataChart,
};
