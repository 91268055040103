import { axiosInstanceAuthorization } from '../Config/AxiosInterceptor';

const getDataTuitionFee = (id: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/student/${id}/infor-tuition`,
  );
};
const sendDataTuitionFee = (body: any): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + `/api/v1/student/pay-tuition`,
    body,
  );
};
export default {
  getDataTuitionFee,
  sendDataTuitionFee,
};
