import UserAPI from '../../api/auth/user';

type metaProps = {
  message: string;
  status: number;
};

type ResponseData = {
  data: any;
  meta: metaProps;
};

const Check = async (): Promise<ResponseData | Error> => {
  try {
    const response = await UserAPI.check();
    const { data } = response;
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default { Check };
