import React, { useState, useEffect } from 'react';
import DatePickerCustom from '@components/DatePicker/datePicker';
import moment from 'moment';
import { Select, Button, Card, Spin } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import './homepage.scss';
import { dateToISODate } from '@utils/Functions/formatDate';
import Endpoint from '@api/listApi/statistical';
import { RootState } from '@store';
import { useSelector } from 'react-redux';

const { RangePicker } = DatePickerCustom;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'Thống kê đóng học phítheo khu vực',
    },
  },
};
export const optionsPie = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'Thống kê lượng học viên khu vực',
    },
  },
};

export const dataPie = {
  labels: [
    'Hà Nội',
    'Tp.HCM',
    'Hải Phòng',
    'Đà Nẵng',
    'Đồng Nai',
    'Các vùng khác',
  ],
  datasets: [
    {
      label: ': số học viên',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
const today = moment();
const dayStartConfig = '2023-08-28';

const HomePage = () => {
  const { user } = useSelector((state: RootState) => state.userName);
  console.log(user, 'user user');
  const [dataTotal, setDataToal] = useState<any>();
  const [timeValue, setTimeValue] = useState([]);
  const [dataChart, setDataChart] = useState<any>();
  const [dataPie, setDataPie] = useState<any>();
  const getDataTotal = async (
    startDate: string,
    endDate: string,
  ): Promise<any | Error> => {
    try {
      const response = await Endpoint.getTotal(startDate, endDate);
      if (response.status === 200 && response.data) {
        setDataToal(response.data.data);
      }
    } catch (error) {
      if (error instanceof Error) {
        return error;
      } else {
        return new Error('Unexpected error');
      }
    }
  };
  const getDataChart = async (
    startDate: string,
    endDate: string,
  ): Promise<any | Error> => {
    try {
      const response = await Endpoint.getDataChart(startDate, endDate);
      if (response.status === 200 && response.data && response.data?.data) {
        const labelCustom = response.data.data?.labels;
        // set vào dữ liệu chart
        setDataChart({
          labels: labelCustom,
          datasets: [
            {
              label: 'Học phí dự kiến',
              data: response.data.data?.totalAmountPaid,
              backgroundColor: '#001529',
            },
            {
              label: 'Học phí đã đóng',
              data: response.data.data?.totalAmount,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        });
        setDataPie({
          labels: labelCustom,
          datasets: [
            {
              label: ': số học viên',
              data: response.data.data?.studentCount,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        return error;
      } else {
        return new Error('Unexpected error');
      }
    }
  };
  useEffect(() => {
    getDataTotal(dayStartConfig, dateToISODate(new Date()));
    getDataChart(dayStartConfig, dateToISODate(new Date()));
  }, []);

  const handleGetdata = async () => {
    setDataChart(null);
    setDataPie(null);
    if (timeValue.length > 0) {
      getDataTotal(dateToISODate(timeValue[0]), dateToISODate(timeValue[1]));
      getDataChart(dateToISODate(timeValue[0]), dateToISODate(timeValue[1]));
    } else {
      getDataTotal(dayStartConfig, dateToISODate(new Date()));
      getDataChart(dayStartConfig, dateToISODate(new Date()));
    }
  };
  return (
    <Spin tip="Loading" spinning={!user}>
      {user.role === 'ADMIN' ? (
        <div className="homepage">
          <div className="homepage-group-input">
            <RangePicker
              className="homepage-group-input-ranger"
              placeholder={['Từ ngày', 'Đến ngày']}
              format="DD/MM/YYYY"
              defaultValue={[moment(dayStartConfig, 'YYYY-MM-DD'), today]}
              onChange={(e) => setTimeValue(e)}
            />
            <Select
              showSearch
              className="homepage-group-input-search"
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              defaultValue={'1'}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[
                {
                  value: '1',
                  label: 'Toàn hệ thống',
                },
              ]}
            />
            <Button onClick={handleGetdata}>
              <RedoOutlined />
            </Button>
          </div>
          {dataTotal && (
            <div className="homepage-group-card">
              <Card
                title="Tổng học phí đã thu"
                bordered={false}
                className="homepage-group-card-item"
              >
                <p className="homepage-group-card-item-text">
                  {new Intl.NumberFormat('en-US').format(dataTotal?.totalMoney)}{' '}
                  ₫
                </p>
              </Card>
              <Card
                title="Số học viên tham gia mới"
                bordered={false}
                className="homepage-group-card-item"
              >
                <p className="homepage-group-card-item-text">
                  {dataTotal?.totalStudent}
                </p>
              </Card>
              <Card
                title="Số lớp học đang triển khai"
                bordered={false}
                className="homepage-group-card-item"
              >
                <p className="homepage-group-card-item-text">
                  {dataTotal?.totalClass}
                </p>
              </Card>
            </div>
          )}
          {dataChart && dataPie && (
            <div className="homepage-group-chart">
              <div className="homepage-group-chart-bar">
                <Bar options={options} data={dataChart} />
              </div>
              <div className="homepage-group-chart-pie">
                <Pie options={optionsPie} data={dataPie} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ backgroundColor: '#000' }}>
          <img
            src="/images/main.jpg"
            style={{
              height: '85vh',
              textAlign: 'center',
              margin: '0 auto',
              display: 'block',
            }}
          />
        </div>
      )}
    </Spin>
  );
};

export default HomePage;
