import React, { useState } from 'react';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { MAX_FILE_SIZE, ALLOWED_TYPE_PHOTO } from '@configs/imageUpload';
import { Upload, notification, Modal, Image } from 'antd';
import { handleUpload } from '@services';

interface UploadCustomProps {
  setImageUrl: (url: string) => void;
  fileList: UploadFile<any>[];
  setFileList: (fileList: UploadFile<any>[]) => void;
  classCustomAdd: string;
  classCustomDetail: string;
}

const UploadCustom: React.FC<UploadCustomProps> = ({
  setImageUrl,
  fileList,
  setFileList,
  classCustomAdd,
  classCustomDetail,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const onChangeImage: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    setIsUploading(true);
    setFileList(newFileList);
  };
  console.log(fileList, 'fileList');
  const customRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    try {
      if (file.size > MAX_FILE_SIZE) {
        return notification.error({
          message: 'Lỗi',
          description: 'Kích thước tệp quá lớn. Vui lòng chọn tệp nhỏ hơn 3MB.',
        });
      }
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!ALLOWED_TYPE_PHOTO.includes(fileExtension)) {
        return notification.error({
          message: 'Loại tệp không hợp lệ',
          description: 'Vui lòng chọn tệp ảnh (jpg, jpeg, png, webp)',
        });
      }
      const newFileName = `${Date.now()}.${fileExtension}`;
      // const newFileName = `test.${fileExtension}`;
      const response: any = await handleUpload.handleUploadS3(
        newFileName,
        file,
        'card_photo',
      );
      onSuccess(response);
      setImageUrl(response);
    } catch (error) {
      onError(error);
    }
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    console.log(src, 'src');

    if (!src) {
      try {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      } catch {
        if (fileList.length > 0) {
          src = fileList[0].response;
        } else {
          return;
        }
      }
    }
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);

    // Hiển thị hình ảnh trong Modal
    Modal.info({
      width: 400,
      title: '',
      content: <Image src={src} alt="Preview" style={{ maxWidth: '100%' }} />,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: 'none' } },
    });
  };
  return (
    <Upload
      customRequest={customRequest}
      listType="picture-card"
      fileList={fileList}
      onChange={onChangeImage}
      onPreview={onPreview}
      className={isUploading ? classCustomAdd : classCustomDetail}
    >
      {fileList.length < 1 && '+ Tải Ảnh'}
    </Upload>
  );
};

export default UploadCustom;
