import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { handleClass, handleStudent } from '@services';
import { OPageIndex } from '@configs/pageSize';
import dataCity from '@configs/dataCity.json';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { breadcrumbListClassTeacher as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListClassTeacher as columns } from '@components/Table/Columns';
import TabelComponent from '@components/Table';
import FilterComponent from '@components/Filter';
import { dataOptionListClassteacher as dataOption } from '@components/Filter/dataOption';
import '../index.scss';

const ClassStudent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [cities, setCities] = useState([]);
  useEffect(() => {
    setCities(dataCity);
    const myisActive = queryParams.get('isActive');
    if (myisActive) {
      if (myisActive === 'true') {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
      } else {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
      }
    }
  }, []);
  useEffect(() => {
    const myText = queryParams.get('text') || '';
    setText(myText);
    getDataTable();
  }, [location.search]);

  const getDataTable = () => {
    const queryParams = new URLSearchParams(location.search);
    const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    setTableLoading(true);
    handleClass
      .GetListClassTeacher(mypageIndex, myisActive, myText)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data.listData);
          setCurrentPage(Number(data.pageIndex));
          setTotalItems(Number(data.totalRecord));
          setTableLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const showModalExcel = () => {
    setOpenExcel(true);
  };

  const hideModalExcel = () => {
    setOpenExcel(false);
  };
  const title = 'Danh sách lớp học';

  const handleDownloadExcel = async () => {
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    handleStudent.downloadExcelStudentTeacher(myisActive, myText);
    setOpenExcel(false);
  };

  const handleEdit = (record: any) => {
    // console.log(record, 'record');
    navigate(`/class/details-list?code=${record.code}`);
  };

  const handleGoAttendance = (record: any) => {
    console.log(record, 'record');
    navigate(`/student-class/attendance?code=${record.code}`);
  };

  const titleButton = '';
  const routerAdd = '';

  const [select1Value, setSelect1Value] = useState(undefined);
  const [select2Value, setSelect2Value] = useState(undefined);

  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmitFilter = () => {
    setIsOpen(!isOpen);
    const params = new URLSearchParams(window.location.search);
    if (select2Value == 'true') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái đang hoạt động',
          field: 'isActive',
          value: true,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'true');
      } else {
        params.append('isActive', 'true');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    } else if (select2Value == 'false') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái không hoạt động',
          field: 'isActive',
          value: false,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'false');
      } else {
        params.append('isActive', 'false');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    }
  };

  const handleChangeInput = (value) => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('text')) {
      params.set('text', value);
    } else {
      params.append('text', value);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const [dataFilter, setDataFilter] = useState([]);
  const handleDeleteFilter = (key) => {
    const updatedDataFilter = dataFilter.filter((item) => item.key !== key);
    setDataFilter(updatedDataFilter);
    const params = new URLSearchParams(window.location.search);
    if (params.has('isActive')) {
      params.delete('isActive');
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} lớp học</span>
    );
  };
  return (
    <>
      <Modal
        title="Tải dữ liệu lớp học"
        open={openExcel}
        onOk={() => handleDownloadExcel()}
        onCancel={() => hideModalExcel()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận tải xuống dữ liệu <strong>{totalItems} lớp học</strong> ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterComponent
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        setText={setText}
        text={text}
        handleChangeInput={handleChangeInput}
        showModalExcel={showModalExcel}
        dataFilter={dataFilter}
        handleDeleteFilter={handleDeleteFilter}
        handleSelect1Change={handleSelect1Change}
        select1Value={select1Value}
        searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
        dataOption={dataOption}
        select2Value={select2Value}
        setSelect2Value={setSelect2Value}
        handleSubmitFilter={handleSubmitFilter}
        placeholderInput={'Tìm kiếm theo tên lớp học, tên khoá học'}
      />
      <div className="table">
        <TabelComponent
          columns={columns({ handleEdit, handleGoAttendance, cities })}
          data={data}
          tableLoading={tableLoading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalItems={totalItems}
          customTotal={customTotal}
        />
      </div>
    </>
  );
};

export default ClassStudent;
