import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

function QRCodeGenerator({ qrData }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, qrData, { scale: 10 }, (error) => {
        if (error) console.error('Error generating QR code:', error);
      });
    }
  }, [qrData]);

  return <canvas ref={canvasRef} />;
}

export default QRCodeGenerator;

export const expiredTime: number = 1800;
