import CourseEndpoint from '../../api/listApi/course';
import axios from 'axios';
import Cookies from 'js-cookie';

const GetList = async (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any | Error> => {
  try {
    const response = await CourseEndpoint.getListCourse(
      pageIndex,
      isActive,
      title,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken'); // Thay đổi key này nếu cần
};

const downloadExcel = async (isActive: string, title: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/list-courses/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'khoahoc_30shineAcademy.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const updateStatus = async (id: string, body: any): Promise<any | Error> => {
  try {
    const response = await CourseEndpoint.statusCourse(id, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const createCourse = async (body: any): Promise<any | Error> => {
  try {
    const response = await CourseEndpoint.createCourse(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const detailCourse = async (id: string): Promise<any | Error> => {
  try {
    const response = await CourseEndpoint.getDetailCourse(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const updateCourse = async (id: string, body: any): Promise<any | Error> => {
  try {
    const response = await CourseEndpoint.updateCourse(id, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default {
  GetList,
  downloadExcel,
  updateStatus,
  createCourse,
  updateCourse,
  detailCourse,
};
