import StudentEndpoint from '../../api/listApi/student';
import axios from 'axios';
import Cookies from 'js-cookie';

const Create = async (body: any): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.createStudent(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const GetList = async (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.getListStudent(
      pageIndex,
      isActive,
      title,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const GetListInClass = async (
  pageIndex: string,
  isActive: string,
  title: string,
  code: string,
): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.getListStudentInClass(
      pageIndex,
      isActive,
      title,
      code,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const GetListOutClass = async (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.getListStudentOutClass(
      pageIndex,
      isActive,
      title,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken'); // Thay đổi key này nếu cần
};

const downloadExcel = async (isActive: string, title: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/users/list-student/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const downloadExcelStudentInClass = async (
  code: string,
  isActive: string,
  title: string,
) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/student/list/${code}/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const downloadExcelStudentNoClass = async (isActive: string, title: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/student/list/no-class/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const downloadExcelStudentTeacher = async (isActive: string, title: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/class/list-class/teacher/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const GetDetailt = async (id: string): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.getDetailStudent(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const updateDetailt = async (
  id: string,
  body: any,
  isActive: boolean,
): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.updateInformation(
      id,
      body,
      isActive,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const updateStatus = async (id: string, body: any): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.updateStatus(id, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const resetPassword = async (id: string): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.resetPassword(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const checkUserName = async (userName: string): Promise<any | Error> => {
  try {
    const response = await StudentEndpoint.checkUserName(userName);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default {
  Create,
  GetList,
  downloadExcel,
  GetDetailt,
  updateDetailt,
  updateStatus,
  resetPassword,
  GetListInClass,
  GetListOutClass,
  checkUserName,
  downloadExcelStudentInClass,
  downloadExcelStudentNoClass,
  downloadExcelStudentTeacher,
};
