import dataCity from '@configs/dataCity.json';

export const dataOptionListStudent = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];

export const dataOptionListClassteacher = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];

export const dataOptionListStudentClassteacher = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];

export const dataOptionListCourse = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];
const newArray = dataCity.map((city: any) => ({
  value: city.Id,
  label: city.Name,
}));

export const dataOptionListClass = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  {
    label: 'Khu vực',
    value: 'area',
    select: newArray,
  },
];

export const dataOptionListStudentClass = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];

export const dataOptionAddStudentClass = [
  {
    label: 'Trạng thái',
    value: 'isActive',
    select: [
      { label: 'Đang hoạt động', value: 'true' },
      { label: 'Không hoạt động', value: 'false' },
    ],
  },
  // {
  //   label: 'Bị đình chỉ học',
  //   value: 'isSuppended',
  //   select: [
  //     { label: 'Có', value: 'true' },
  //     { label: 'Không', value: 'false' },
  //   ],
  // },
];
