import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Spin } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { handleCourse } from '@services';
import '@pages/index.scss';
import moment from 'moment';
import { breadcrumbDetailCourse as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustom from '@components/UploadImage';
import CourseForm from '@components/Form/Course';
import { dateToISODate } from '@utils/Functions/formatDate';

const DetailCourses = () => {
  const [initialValues, setInitialValues] = useState<any>();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl1, setImageUrl1] = useState<string>();
  const title = 'Thông tin khóa học';

  const titleButton = '';
  const routerAdd = '';
  useEffect(() => {
    setIsLoading(true);
    getDataDetail();
  }, [window.location.search]);

  const getDataDetail = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get('code');
    if (codeParam) {
      handleCourse
        .detailCourse(codeParam)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setData(data);
            setIsLoading(false);
          } else if (statusCode === 403) {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    if (data) {
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);
      // const identificationDate = new Date(data.IdentificationDate);
      const studyFlow1 = data.studyFlow[0];
      const startDateFlow1 = new Date(studyFlow1.startDate);
      const endDateFlow1 = new Date(studyFlow1.endDate);
      const studyFlow2 = data.studyFlow[1];
      const startDateFlow2 = new Date(studyFlow2.startDate);
      const endDateFlow2 = new Date(studyFlow2.endDate);
      const initValues = {
        name: data.name,
        tuition: data.totalTuitionFee,
        date: [moment.utc(startDate), moment.utc(endDate)],
        sessionsNumber: data.numberSessions,
        note: data.note,
        is_active: data.isActive,
        flow1: {
          name: studyFlow1.name,
          numberSessions: studyFlow1.numberSessions,
          maximumDaysOff: studyFlow1.maximumDaysOff,
          numberExercises: studyFlow1.numberExercises,
          date: [moment.utc(startDateFlow1), moment.utc(endDateFlow1)],
          estimatedExamDate: null,
          note: studyFlow1.note,
        },
        flow2: {
          name: studyFlow2.name,
          numberSessions: studyFlow2.numberSessions,
          maximumDaysOff: studyFlow2.maximumDaysOff,
          numberExercises: studyFlow2.numberExercises,
          date: [moment.utc(startDateFlow2), moment.utc(endDateFlow2)],
          estimatedExamDate: null,
          note: studyFlow2.note,
        },
      };
      setInitialValues(initValues);
      setImageUrl1(data.avatar_origin);
      setImageUrl(data.imageStudyFlow_origin);
    }
  }, [data]);

  const onFinish = (values: any) => {
    // check xem ảnh đã được tải lên chưa
    if (!imageUrl) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh lộ trình khóa học chưa được tải lên',
      });
    }
    if (!imageUrl1) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh chứng chỉ khóa học chưa được tải lên',
      });
    }
    if (
      Number(values.sessionsNumber) !==
      Number(values.flow1?.numberSessions) +
        Number(values.flow2?.numberSessions)
    ) {
      return notification.error({
        message: 'Lỗi',
        description:
          'Tổng số buổi học trong giai đoạn phải bằng số buổi học của khóa học !',
      });
    }
    const dataValue = {
      name: values.name,
      totalTuitionFee: values.tuition,
      numberSessions: values.sessionsNumber,
      startDate: dateToISODate(values.date[0]),
      endDate: dateToISODate(values.date[1]),
      note: values.note,
      avatar: imageUrl1,
      imageStudyFlow: imageUrl,
      isActive: values.is_active,
      studyFlow: [
        {
          name: values.flow1.name,
          numberSessions: values.flow1.numberSessions,
          maximumDaysOff: values.flow1.maximumDaysOff,
          numberExercises: values.flow1.numberExercises,
          startDate: dateToISODate(values.flow1.date[0]),
          endDate: dateToISODate(values.flow1.date[1]),
          estimatedExamDate: values.flow1.estimatedExamDate,
          note: values.flow1.note,
        },
        {
          name: values.flow2.name,
          numberSessions: values.flow2.numberSessions,
          maximumDaysOff: values.flow2.maximumDaysOff,
          numberExercises: values.flow2.numberExercises,
          startDate: values.flow2.date[0],
          endDate: values.flow2.date[1],
          estimatedExamDate: values.flow2.estimatedExamDate,
          note: values.flow2.note,
        },
      ],
    };
    // console.log(data, 'data');
    setIsLoading(true);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get('code');
    handleCourse
      .updateCourse(codeParam, dataValue)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công cập nhật dữ liệu cho khóa học',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setIsLoading(false);
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };
  // day anh vao list de hien thi
  useEffect(() => {
    if (data) {
      if (data.avatar) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.avatar,
            thumbUrl: data.avatar,
          },
        ];
        setFileList1(mappedData);
      }
      if (data.imageStudyFlow) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.imageStudyFlow,
            thumbUrl: data.imageStudyFlow,
          },
        ];
        setFileList(mappedData);
      }
    }
  }, [data]);
  // cấu hình lại tải ảnh
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <div className="main main-add">
          <div className="main-left">
            <div className="main-left-block">
              <div
                className="main-left-block-title"
                onClick={() => toggleCollapse1()}
              >
                {collapsed1 ? (
                  <RightOutlined
                    className="main-left-block-title-icon"
                    style={{ marginRight: 5 }}
                  />
                ) : (
                  <DownOutlined
                    className="main-left-block-title-icon"
                    style={{ marginRight: 5 }}
                  />
                )}
                Thông tin khóa học
              </div>
              {initialValues && (
                <CourseForm
                  initialValues={initialValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  collapsed1={collapsed1}
                  collapsed2={collapsed2}
                  toggleCollapse2={toggleCollapse2}
                  isDetail={true}
                />
              )}
            </div>
          </div>
          <div className="main-right">
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh lộ
                trình khóa học
              </h4>
              <UploadCustom
                setImageUrl={setImageUrl}
                fileList={fileList}
                setFileList={setFileList}
                classCustomAdd="upload-block-5-add"
                classCustomDetail="upload-block-5"
              ></UploadCustom>
            </div>
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh chứng
                chỉ khóa học
              </h4>
              <UploadCustom
                setImageUrl={setImageUrl1}
                fileList={fileList1}
                setFileList={setFileList1}
                classCustomAdd="upload-block-4-add"
                classCustomDetail="upload-block-4"
              ></UploadCustom>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailCourses;
