import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { handleStudent } from '@services';
import { useNavigate } from 'react-router-dom';
import dataCity from '@configs/dataCity.json';
import { breadcrumbAddStudent as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustom from '@components/UploadImage';
import StudentForm from '@components/Form/StudentForm';
import { dateToISODate } from '@utils/Functions/formatDate';
import '../index.scss';

const AddStudent = () => {
  const navigate = useNavigate();
  const initialValues = {
    gender: true,
  };
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [wards, setWards] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl1, setImageUrl1] = useState<string>();
  const [imageUrl2, setImageUrl2] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);

  const title = 'Thêm mới học viên';
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    setCities(dataCity);
  }, []);
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedDistrict('');
    setWards([]);
    const city = cities.find((city) => city.Id === value);
    if (city) {
      setDistricts(city.Districts);
    }
  };

  const handleDistrictChange = (value) => {
    setSelectedDistrict(value);
    const city = cities.find((city) => city.Id === selectedCity);
    if (city) {
      const district = city.Districts.find((district) => district.Id === value);
      if (district) {
        setWards(district.Wards);
      }
    }
  };

  const onFinish = (values: any) => {
    if (!imageUrl) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh thẻ 3x4 của học viên chưa được tải lên',
      });
    }
    if (!imageUrl1 || !imageUrl2) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh CCCD của học viên chưa được tải lên',
      });
    }
    const data = {
      userName: values.userName,
      information: {
        name: values.name,
        identificationNumber: values.identificationNumber,
        IdentificationDate: dateToISODate(values.IdentificationDate),
        IdentificationAdress: values.IdentificationAdress,
        DOB: dateToISODate(values.DOB),
        gender: values.gender,
        city: values.city,
        district: values.district,
        wards: values.wards,
        street: values.street,
        phone: values.phone,
        email: values.email,
        parentPhone: values.parentPhone,
        wishToJoin: values.wishToJoin,
        note: values.note,
        avatar: imageUrl,
        identificationImage: [imageUrl1, imageUrl2],
      },
    };
    handleStudent
      .Create(data)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo tài khoản học viên',
          });
          navigate('/list-students');
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Tài khoản hoặc mật khẩu không chính xác !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleOpen = () => {
    console.log('oke');
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <StudentForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            selectedCity={selectedCity}
            handleCityChange={handleCityChange}
            cities={cities}
            selectedDistrict={selectedDistrict}
            handleDistrictChange={handleDistrictChange}
            districts={districts}
            wards={wards}
            isDetail={false}
            isDisabled={false}
            handleOpen={handleOpen}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh chân
              dung 3x4
            </h4>
            <UploadCustom
              setImageUrl={setImageUrl}
              fileList={fileList}
              setFileList={setFileList}
              classCustomAdd="upload-block-1-add"
              classCustomDetail="upload-block-1-add"
            ></UploadCustom>
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh 2 mặt
              CCCD
            </h4>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <div
                  style={{ textAlign: 'center' }}
                  className="upload-block-title"
                >
                  Mặt trước
                </div>
                <UploadCustom
                  setImageUrl={setImageUrl1}
                  fileList={fileList1}
                  setFileList={setFileList1}
                  classCustomAdd="upload-block-2-add"
                  classCustomDetail="upload-block-2-add"
                ></UploadCustom>
              </div>
              <div style={{ width: '50%' }}>
                <div
                  style={{ textAlign: 'center' }}
                  className="upload-block-title"
                >
                  Mặt sau
                </div>
                <UploadCustom
                  setImageUrl={setImageUrl2}
                  fileList={fileList2}
                  setFileList={setFileList2}
                  classCustomAdd="upload-block-2-add"
                  classCustomDetail="upload-block-2-add"
                ></UploadCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
