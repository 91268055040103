import { Link } from 'react-router-dom';

export const breadcrumbListStudent = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách học viên' },
];

export const breadcrumbAddStudent = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-students'}>Danh sách học viên</Link> },
  { title: 'Thêm mới học viên' },
];

export const breadcrumbDetailStudent = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-students'}>Danh sách học viên</Link> },
  { title: 'Thông tin học viên' },
];

export const breadcrumbDetailStudentTeacher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/student-class'}>Danh sách lớp học</Link> },
  { title: 'Thông tin học viên' },
];

export const breadcrumbAttendanceTeacher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/student-class'}>Danh sách lớp học</Link> },
  { title: 'Điểm danh lớp' },
];

export const breadcrumbListClassTeacher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách lớp học' },
];

export const breadcrumbListStudentClassTeacher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/student-class'}>Danh sách lớp học</Link> },
  { title: 'Danh sách học viên trong lớp' },
];

export const breadcrumbTuitionTeacher = (studentInfor) => {
  return [
    { title: <Link to={'/'}>Trang chủ</Link> },
    { title: <Link to={'/student-class'}>Danh sách lớp học</Link> },
    {
      title: (
        <Link to={`/class/details-list?code=${studentInfor?.classCode}`}>
          Danh sách học viên trong lớp
        </Link>
      ),
    },
    { title: 'Nộp thêm học phí' },
  ];
};

export const breadcrumbListCourse = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khóa học' },
];

export const breadcrumbAddCourse = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-courses'}>Danh sách khóa học</Link> },
  { title: 'Tạo khóa học' },
];

export const breadcrumbDetailCourse = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-courses'}>Danh sách khóa học</Link> },
  { title: 'Thông tin khóa học' },
];

export const breadcrumbAddClass = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
  { title: 'Tạo lớp học' },
];

export const breadcrumbDetailClass = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
  { title: 'Chi tiết lớp học' },
];

export const breadcrumbListClass = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách lớp học' },
];

export const breadcrumbListStudentClass = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
  { title: 'Danh sách học viên trong lớp' },
];

export const breadcrumbAddStudentClass = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
  { title: 'Thêm học viên vào lớp' },
];

export const breadcrumbAttendance = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
  { title: 'Điểm danh lớp' },
];

export const breadcrumbExam = (examData) => {
  return [
    { title: <Link to={'/'}>Trang chủ</Link> },
    { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
    {
      title: (
        <Link
          to={
            examData
              ? `/list-class/detail?code=${examData.codeClass}`
              : `/list-class`
          }
        >
          Danh sách học viên trong lớp
        </Link>
      ),
    },
    { title: 'Danh sách học viên trong lớp' },
  ];
};

export const breadcrumbTuition = (studentInfor) => {
  return [
    { title: <Link to={'/'}>Trang chủ</Link> },
    { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
    {
      title: (
        <Link
          to={
            studentInfor
              ? `/list-class/detail?code=${studentInfor.classCode}`
              : `/list-class`
          }
        >
          Danh sách học viên trong lớp
        </Link>
      ),
    },
    { title: 'Nộp thêm học phí' },
  ];
};
