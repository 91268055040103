import AuthAPI from '../../api/auth/login';

type metaProps = {
  message: string;
  status: number;
};

type ResponseData = {
  data: any;
  meta: metaProps;
};

const Login = async (
  userName: string,
  password: string,
): Promise<ResponseData | Error> => {
  try {
    const response = await AuthAPI.login(userName, password);
    const { data } = response;
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default { Login };
