import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Tabs } from 'antd';
import ExamApi from '@api/listApi/exam';
import { Breadcrumb as AntdBreadcrumb, Spin, notification } from 'antd';
import { additionalArray } from './config';
import moment from 'moment';
import { breadcrumbExam } from '@components/Breadcrumb';
import { ColumnsExam as columns } from '@components/Table/Columns';
import type { TabsProps } from 'antd';
import '../index.scss';

const ExamPages = () => {
  const [open, setOpen] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const examParam = urlSearchParams.get('exam');
  const idParam = urlSearchParams.get('id');
  const [studentInfor, setStudentInfor] = useState<any>();
  const [type, setType] = useState<any>(examParam ? examParam : 'midterm');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [examSelected, setExamSelected] = useState([]);
  const [examData, setExamData] = useState<any>();
  const [combinedArray, setCombinedArray] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const items: TabsProps['items'] = [
    {
      key: 'midterm',
      label: `Điểm thi quá trình`,
    },
    {
      key: 'semester',
      label: `Điểm thi tốt nghiệp`,
    },
  ];
  const showModal = () => {
    setOpen(true);
  };
  const getDataTable = async (id) => {
    try {
      setIsLoading(true);
      const response = await ExamApi.getDataExam(id, type);
      setStudentInfor(response.data.data);
      setExamData(response.data.data);
      setExamSelected(response.data.data.dataExam);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Thất bại',
        description: 'Học viên chưa có bài thi này',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (examSelected && examSelected?.length === 4) {
      const combinedArrayItem = [];
      examSelected.forEach((evaluateItem, index) => {
        combinedArrayItem.push({
          ...evaluateItem,
          ...additionalArray[index],
        });
      });
      setCombinedArray(combinedArrayItem);
    } else {
      setCombinedArray([]);
    }
  }, [examSelected]);
  useEffect(() => {
    if (idParam) {
      getDataTable(idParam);
    }
  }, [type]);
  const okeModal = async () => {
    try {
      if (combinedArray?.length === 4) {
        setIsLoading(true);
        // tiến hành lọc combinedArray
        const filteredArray = combinedArray.map(
          ({ photo_origin, value, key }) => ({
            photo: photo_origin,
            value,
            key,
          }),
        );

        const param = {
          userId: studentInfor.userId,
          examId: studentInfor.examId,
          studentId: studentInfor.studentId,
          isPass: true,
          exam: type,
          evaluate: filteredArray,
        };
        const response = await ExamApi.sendDataExam(param);
        setIsLoading(false);
        if (response.data?.statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã hoàn thành chấm điểm học viên',
          });
          getDataTable(idParam);
        } else {
          notification.error({
            message: 'Thất bại',
            description: response?.message,
          });
        }
      }
      setOpen(false);
    } catch (error) {
      setOpen(false);
      notification.error({
        message: 'Thất bại',
        description: 'Lỗi hệ thống. Vui lòng tải lại trang và thử lại',
      });
    }
  };

  const hideModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (examData) {
      setBreadcrumbItems(breadcrumbExam(examData));
    }
  }, [examData]);

  const onHandleSelected = (dataExam) => {
    const check = combinedArray.find((c) => c.key === dataExam.key);
    if (check) {
      check.value = dataExam.value;
      setCombinedArray([...combinedArray]);
    } else {
      setCombinedArray([...combinedArray, dataExam]);
    }
  };

  const onChange = (key: string) => {
    setType(key);
  };

  return (
    <>
      <Modal
        open={open}
        onOk={okeModal}
        onCancel={hideModal}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <h2>Xác nhận Đạt ?</h2>
        <p>(Hành động này sẽ không được làm lại)</p>
      </Modal>
      <div className="titlebox">
        <AntdBreadcrumb
          items={breadcrumbItems}
          className="titlebox-breadcrumb"
        />
        <div className="">
          <h2 className="titlebox-body-title">Chấm điểm</h2>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <div className="titlebox" style={{ marginTop: '10px' }}>
          <p>Mã học viên: {studentInfor && studentInfor.code}</p>
          <p>Tên học viên: {studentInfor && studentInfor.name}</p>
          <p>Khóa học: {studentInfor && studentInfor.course}</p>
          <p>Lớp học: {studentInfor && studentInfor.class}</p>
          <p>
            Ngày thi:{' '}
            {studentInfor && moment.utc(studentInfor.date).format('DD/MM/yyyy')}
          </p>
        </div>
        <div className="table">
          {combinedArray?.length > 0 && (
            <Table
              columns={columns({ onHandleSelected })}
              dataSource={combinedArray}
              pagination={false}
            />
          )}
        </div>
      </Spin>
      {combinedArray.length > 0 ? (
        <div
          className=""
          style={{ display: 'flex', justifyContent: 'right', marginBottom: 20 }}
        >
          <Button
            type="primary"
            className="button-submit"
            htmlType="submit"
            onClick={showModal}
            disabled={studentInfor && studentInfor.isPass}
          >
            {studentInfor && studentInfor.isPass
              ? 'Đã pass qua khóa học'
              : `Xác nhận đạt ${combinedArray.filter((c) => c.value)?.length}/${
                  studentInfor && studentInfor.dataExam?.length
                }`}
          </Button>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: '#fff',
            marginTop: 10,
            textAlign: 'center',
          }}
        >
          <img src="/images/noExams.png" width={360} />
          <div style={{ fontSize: '15px', fontWeight: 600, padding: 20 }}>
            Học viên chưa có bài thi này !
          </div>
        </div>
      )}
    </>
  );
};

export default ExamPages;
