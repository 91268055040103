import { handleStudent } from '../../services/index';

const validatePassword = async (password: string): Promise<string> => {
  const regex = new RegExp(
    '^(?=[a-zA-Z0-9._@!#$%^&*]{6,18}$)(?!.*[_.]{2})[^_.].*[^_.]$',
  );

  if (!regex.test(password)) {
    throw new Error(
      'Mật khẩu phải từ 6 đến 18 ký tự và không chứa các ký tự đặc biệt!',
    );
  }

  return '';
};

const validateUsername = async (username: string): Promise<string> => {
  const regex = new RegExp(
    '^(?=[a-zA-Z0-9._]{3,30}$)(?!.*[_.]{2})[^_.].*[^_.]$',
  );

  if (!regex.test(username)) {
    throw new Error(
      'Tên đăng nhập phải từ 3 đến 30 ký tự và không chứa các ký tự đặc biệt!',
    );
  }

  return '';
};

const validatePasswordChange = (password: string): boolean => {
  const regex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}$',
  );

  return regex.test(password);
};

const validateIdentificationNumber = (_, value) => {
  // console.log(value, value.length);
  if (!value) {
    return Promise.reject('Vui lòng nhập giá trị');
  }
  if (isNaN(value)) {
    return Promise.reject('Căn cước công dân là số');
  }
  if (value.toString().length !== 12) {
    return Promise.reject('Căn cước công dân phải có chính xác 12 số');
  }
  if (value.toString().includes(' ')) {
    return Promise.reject('Không được chứa khoảng trắng');
  }
  return Promise.resolve();
};

const validatePhoneNumber = (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập SĐT');
  }
  if (isNaN(value)) {
    return Promise.reject('SĐT phải là số');
  }
  if (value.includes(' ')) {
    return Promise.reject('K được có dấu cách');
  }
  if (!/^0/.test(value)) {
    return Promise.reject('SĐT phải bđ là 0');
  }
  if (value.length !== 10) {
    return Promise.reject('SĐT phải có 10 số');
  }
  return Promise.resolve();
};

const validateEmail = (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập địa chỉ email');
  }
  if (value.includes(' ')) {
    return Promise.reject('Không được chứa khoảng trắng');
  }
  // Sử dụng biểu thức chính quy để kiểm tra định dạng email
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  if (!emailPattern.test(value)) {
    return Promise.reject('Định dạng email không hợp lệ');
  }
  return Promise.resolve();
};

const validateUserName = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên đăng nhập');
  }
  if (value.length < 6) {
    return Promise.reject('Tên đăng nhập phải có ít nhất 6 kí tự');
  }
  if (value.includes(' ')) {
    return Promise.reject('Tên đăng nhập không được chứa khoảng trắng');
  }

  try {
    const response = await handleStudent.checkUserName(value);
    const { statusCode, data } = response;
    if (statusCode === 200 && !data) {
      return Promise.reject('Tên đăng nhập đã tồn tại hoặc không hợp lệ');
    }
  } catch (error) {
    console.error('Error checking userName:', error);
    return Promise.reject('Hệ thống gặp trục trặc !');
  }

  return Promise.resolve();
};

const validateAdress = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập nơi cấp CCCD');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa ít nhất một từ (không chỉ là khoảng trắng)
  if (!/\S/.test(value)) {
    return Promise.reject('Địa chỉ không được để trống');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa số 0-9
  if (/\d/.test(value)) {
    return Promise.reject('Nơi cấp không được chứa số');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa hai khoảng trắng liên tiếp
  if (/\s{2,}/.test(value)) {
    return Promise.reject('Không được chứa 2 khoảng trắng liên tiếp trở lên');
  }

  // Kiểm tra nếu chuỗi địa chỉ bắt đầu bằng khoảng trắng
  if (/^\s/.test(value)) {
    return Promise.reject('Địa chỉ không được bắt đầu bằng khoảng trắng');
  }

  return Promise.resolve();
};

const validateFullName = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập Họ và tên');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa ít nhất một từ (không chỉ là khoảng trắng)
  if (!/\S/.test(value)) {
    return Promise.reject('Họ và tên không được để trống');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa số 0-9
  if (/\d/.test(value)) {
    return Promise.reject('Họ và tên không được chứa số');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa hai khoảng trắng liên tiếp
  if (/\s{2,}/.test(value)) {
    return Promise.reject('Không được chứa 2 khoảng trắng liên tiếp trở lên');
  }

  // Kiểm tra nếu chuỗi địa chỉ bắt đầu bằng khoảng trắng
  if (/^\s/.test(value)) {
    return Promise.reject('Họ và tên không được bắt đầu bằng khoảng trắng');
  }

  return Promise.resolve();
};

const validateNameCourse = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên khóa học');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa ít nhất một từ (không chỉ là khoảng trắng)
  if (!/\S/.test(value)) {
    return Promise.reject('Tên khóa học không được để trống');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa hai khoảng trắng liên tiếp
  if (/\s{2,}/.test(value)) {
    return Promise.reject('Không được chứa 2 khoảng trắng liên tiếp trở lên');
  }

  // Kiểm tra nếu chuỗi địa chỉ bắt đầu bằng khoảng trắng
  if (/^\s/.test(value)) {
    return Promise.reject('Tên khóa học không được bắt đầu bằng khoảng trắng');
  }

  return Promise.resolve();
};

const validateNameStudyFlow = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên giai đoạn học');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa ít nhất một từ (không chỉ là khoảng trắng)
  if (!/\S/.test(value)) {
    return Promise.reject('Tên giai đoạn học không được để trống');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa hai khoảng trắng liên tiếp
  if (/\s{2,}/.test(value)) {
    return Promise.reject('Không được chứa 2 khoảng trắng liên tiếp trở lên');
  }

  // Kiểm tra nếu chuỗi địa chỉ bắt đầu bằng khoảng trắng
  if (/^\s/.test(value)) {
    return Promise.reject(
      'Tên giai đoạn học không được bắt đầu bằng khoảng trắng',
    );
  }

  return Promise.resolve();
};

const validateNameClass = async (_, value) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên khóa học');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa ít nhất một từ (không chỉ là khoảng trắng)
  if (!/\S/.test(value)) {
    return Promise.reject('Tên khóa học không được để trống');
  }

  // Kiểm tra nếu chuỗi địa chỉ chứa hai khoảng trắng liên tiếp
  if (/\s{2,}/.test(value)) {
    return Promise.reject('Không được chứa 2 khoảng trắng liên tiếp trở lên');
  }

  // Kiểm tra nếu chuỗi địa chỉ bắt đầu bằng khoảng trắng
  if (/^\s/.test(value)) {
    return Promise.reject('Tên khóa học không được bắt đầu bằng khoảng trắng');
  }

  return Promise.resolve();
};

const validateMoneyField = async (_, value) => {
  console.log(value);
  // if (!value) {
  //   return Promise.reject('Vui lòng nhập giá trị');
  // }

  if (!/^\d+$/.test(value)) {
    return Promise.reject('Trường này phải là số');
  }

  if (value.toString().includes(' ')) {
    return Promise.reject('Trường này không được chứa khoảng trắng');
  }

  // if (value.toString().startsWith('0')) {
  //   return Promise.reject('Trường này không được bắt đầu bằng số 0');
  // }

  return Promise.resolve();
};

export {
  validatePassword,
  validateUsername,
  validatePasswordChange,
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateUserName,
  validateAdress,
  validateFullName,
  validateMoneyField,
  validateNameCourse,
  validateNameClass,
  validateNameStudyFlow,
};
