export const additionalArray = [
  {
    key: '1',
    title: 'Hình khối',
    imgDemo: '/images/exam1.png',
  },
  {
    key: '2',
    title: 'Liên kết',
    imgDemo: '/images/exam2.png',
  },
  {
    key: '3',
    title: 'Sắc nét',
    imgDemo: '/images/exam3.png',
  },
  {
    key: '4',
    title: 'Toàn diện',
    imgDemo: '/images/exam4.png',
  },
];
