import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../store';
import { fetchUserInfo } from '../store/auth/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../layouts/DashboardLayout';
import DashboardTeacherLayout from '../layouts/DashboardTeacherLayout';
import LoadingPage from '../components/DashboardLayout/Loading';

const PrivateRoute = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.userName);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Gửi request GET tới server khi ứng dụng được khởi chạy
    dispatch(fetchUserInfo())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading) {
    return <LoadingPage />;
  }
  console.log(user, 'user');

  if (user) {
    if (user.role === 'ADMIN') {
      return <DashboardLayout>{children}</DashboardLayout>;
    } else if (user.role === 'TEACHER') {
      return <DashboardTeacherLayout>{children}</DashboardTeacherLayout>;
    } else {
      navigate('/login', { replace: true });
      return null;
    }
  } else {
    navigate('/login', { replace: true });
    return null;
  }
};

export default PrivateRoute;
