import {
  axiosInstanceAuthorization,
  axiosInstanceAuthorizationUpload,
  axiosUploadToS3,
} from '../Config/AxiosInterceptor';

interface IbodyKeyS3 {
  key: string;
}

const uploadImages = (formData: any): Promise<any> => {
  return axiosInstanceAuthorizationUpload.post(
    process.env.REACT_APP_BASE_URL + '/api/v1/uploads/upload-one',
    formData,
  );
};

const getKeyS3EndPoint = (bodyKeyS3: IbodyKeyS3): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + '/api/v1/s3/upload',
    bodyKeyS3,
  );
};

const uploadImageS3 = (urlEndPoint: string, file: any): Promise<any> => {
  return axiosUploadToS3.put(urlEndPoint, file);
};

export default { uploadImages, getKeyS3EndPoint, uploadImageS3 };
