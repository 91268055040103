import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, notification } from 'antd';
import { handleStudent, handleClass } from '@services';
import { OPageIndex } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import TabelComponent from '@components/Table';
import FilterComponent from '@components/Filter';
import { breadcrumbListStudentClass as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListStudentClass as columns } from '@components/Table/Columns';
import { dataOptionListStudentClass as dataOption } from '@components/Filter/dataOption';
import '../../index.scss';

const StudentClass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [numberExercises, setNumberExercises] = useState(0);
  const [numberSessions, setNumberSessions] = useState(0);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [dataFilter, setDataFilter] = useState([]);
  const titleButton = '';
  const routerAdd = '';
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  useEffect(() => {
    const myisActive = queryParams.get('isActive');
    if (myisActive) {
      if (myisActive === 'true') {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
      } else {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
      }
    }
  }, []);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get('code');
    if (codeParam) {
      handleClass
        .detailClass(codeParam)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setTitle(data.name);
            setNumberExercises(
              data.studyFlow?.reduce(
                (total, flow) => total + flow.numberExercises,
                0,
              ),
            );
            setNumberSessions(data.numberSessions);
          } else if (statusCode === 403) {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getDataTable();
  }, [location.search]);

  const getDataTable = () => {
    const queryParams = new URLSearchParams(location.search);
    const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
    const myisActive = queryParams.get('isActive');
    const code = queryParams.get('code');
    const myText = queryParams.get('text') || '';
    setTableLoading(true);
    handleStudent
      .GetListInClass(mypageIndex, myisActive, myText, code)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data.listData);
          setCurrentPage(Number(data.pageIndex));
          setTotalItems(Number(data.totalRecord));
          setTableLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const showModalExcel = () => {
    setOpenExcel(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };
  const hideModalExcel = () => {
    setOpenExcel(false);
  };

  const handleDownloadExcel = async () => {
    const code = queryParams.get('code');
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    handleStudent.downloadExcelStudentInClass(code, myisActive, myText);
    setOpenExcel(false);
  };
  const handlePayTuition = (record: any) => {
    console.log(record, 'record');
    navigate(`/pay-tuition?id=${record.id}`);
  };
  const handleGoMarkExam = (record: any) => {
    console.log(record, 'record');
    navigate(`/mark-exam?exam=midterm&id=${record.id}`);
  };

  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmitFilter = () => {
    setIsOpen(!isOpen);
    const params = new URLSearchParams(window.location.search);
    if (select2Value == 'true') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái đang hoạt động',
          field: 'isActive',
          value: true,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'true');
      } else {
        params.append('isActive', 'true');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    } else if (select2Value == 'false') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái không hoạt động',
          field: 'isActive',
          value: false,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'false');
      } else {
        params.append('isActive', 'false');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    }
  };

  const handleChangeInput = (value) => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('text')) {
      params.set('text', value);
    } else {
      params.append('text', value);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleDeleteFilter = (key) => {
    const updatedDataFilter = dataFilter.filter((item) => item.key !== key);
    setDataFilter(updatedDataFilter);
    const params = new URLSearchParams(window.location.search);
    if (params.has('isActive')) {
      params.delete('isActive');
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} học viên</span>
    );
  };
  return (
    <>
      <Modal
        title="Xóa Lớp học"
        open={open}
        onCancel={() => hideModal()}
        footer={
          modalData?.canDelete ? (
            <div>
              <Button onClick={() => hideModal()}>Hủy bỏ</Button>
            </div>
          ) : null
        }
      >
        {modalData && (
          <>
            {modalData.canDelete ? (
              <p>
                Bạn đồng ý xóa dữ liệu lớp học <strong>{modalData.name}</strong>{' '}
                khỏi hệ thống ?
              </p>
            ) : (
              <p>
                Xin lỗi, bạn không thể xóa dữ liệu khóa học này. Vui lòng xóa
                toàn bộ các học viên trong lớp học này trước !!
              </p>
            )}
          </>
        )}
      </Modal>
      <Modal
        title="Tải dữ liệu lớp học"
        open={openExcel}
        onOk={() => handleDownloadExcel()}
        onCancel={() => hideModalExcel()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận tải xuống dữ liệu <strong>{totalItems} học viên</strong> ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={`Danh sách học viên trong lớp ` + title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterComponent
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        setText={setText}
        text={text}
        handleChangeInput={handleChangeInput}
        showModalExcel={showModalExcel}
        dataFilter={dataFilter}
        handleDeleteFilter={handleDeleteFilter}
        handleSelect1Change={handleSelect1Change}
        select1Value={select1Value}
        searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
        dataOption={dataOption}
        select2Value={select2Value}
        setSelect2Value={setSelect2Value}
        handleSubmitFilter={handleSubmitFilter}
        placeholderInput={'Tìm kiếm theo mã học viên, tên học viên'}
      />
      <TabelComponent
        columns={columns({
          numberSessions,
          numberExercises,
          handleGoMarkExam,
          handlePayTuition,
        })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </>
  );
};

export default StudentClass;
