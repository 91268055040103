import {
  axiosInstanceAuthorization,
  axiosInstanceAuthorizationExcel,
} from '../Config/AxiosInterceptor';
import { OPageSize } from '../../configs/pageSize';
import { ICrateStudent, IupdateStudent } from '../../configs/typeAxios';

const createStudent = (body: ICrateStudent): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + '/api/v1/users/create-student',
    body,
  );
};

const checkUserName = (userName: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL +
      `/api/v1/users/check-username?userName=${userName}`,
  );
};

const getListStudent = (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/users/list-student?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';

  return axiosInstanceAuthorization.get(url);
};

const exportListStudent = (isActive: string, title: string): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL + `/api/v1/users/list-student/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }

  return axiosInstanceAuthorizationExcel.get(url);
};

const getListStudentInClass = (
  pageIndex: string,
  isActive: string,
  title: string,
  code: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/student/list/${code}?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';

  return axiosInstanceAuthorization.get(url);
};

const getListStudentOutClass = (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/student/list/no-class?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';

  return axiosInstanceAuthorization.get(url);
};

const getDetailStudent = (id: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/users/${id}/detail`,
  );
};

const updateInformation = (
  id: string,
  body: IupdateStudent,
  isActive: boolean,
): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL +
      `/api/v1/users/${id}/update?isActive=${isActive}`,
    body,
  );
};

const updateStatus = (id: string, body: IupdateStudent): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/users/${id}/update-status`,
    body,
  );
};

const resetPassword = (id: string): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/users/${id}/reset-password`,
  );
};

export default {
  createStudent,
  getListStudent,
  exportListStudent,
  getDetailStudent,
  updateInformation,
  updateStatus,
  resetPassword,
  getListStudentInClass,
  getListStudentOutClass,
  checkUserName,
};
