import { Button, Form, InputNumber, Image } from 'antd';
import DatePickerCustom from '@components/DatePicker/datePicker';
import moment from 'moment';
import UploadCustom from '@components/UploadImage';
import { validateMoneyField } from '@utils/Functions/validate';
import { CheckOutlined } from '@ant-design/icons';

const TuitionForm = ({
  studentInfor,
  setDateSendMoney,
  setTotalMoney,
  setImageUrl,
  fileList,
  setFileList,
  onHandleSend,
  isValidate,
  lastPayTuition,
  buttonDisabled,
}) => {
  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      className="main-add-form"
      style={{ marginTop: '5px' }}
    >
      <div
        style={{
          backgroundColor: 'white',
          height: '100%',
          marginBottom: 15,
          padding: '10px 0',
        }}
      >
        <Form.Item
          label="Tên học viên - ID"
          name="userName"
          style={{ margin: 0 }}
        >
          <p style={{ marginTop: '5px', fontWeight: 600 }}>
            {studentInfor && `${studentInfor.name} - ${studentInfor.code}`}
          </p>
        </Form.Item>
        <Form.Item label="Ngày nhập học" name="name" style={{ margin: 0 }}>
          <p style={{ marginTop: '5px', fontWeight: 600 }}>
            {studentInfor &&
              moment.utc(studentInfor.startDate).format('DD/MM/yyyy')}
          </p>
        </Form.Item>
        <Form.Item
          label="Khóa học"
          name="identificationNumber"
          style={{ margin: 0 }}
        >
          <p style={{ marginTop: '5px', fontWeight: 600 }}>
            {studentInfor && studentInfor.courseName}
          </p>
        </Form.Item>
        <Form.Item
          label="Lớp học"
          name=""
          style={{ margin: 0, fontWeight: 600 }}
        >
          <p style={{ marginTop: '5px' }}>
            {studentInfor && studentInfor.className}
          </p>
        </Form.Item>
      </div>
      {isValidate ? (
        <div
          style={{
            backgroundColor: 'white',
            marginTop: '10px',
            paddingTop: 20,
            paddingBottom: 15,
          }}
        >
          <CheckOutlined className="tuition-last-icon" />
          <div className="tuition-last-title">
            Đã xác nhận đóng đầy đủ học phí
          </div>
          <div className="tuition-last-wrap">
            <Image
              src={lastPayTuition.photo}
              className="tuition-last-preview"
            />
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundColor: 'white',
              marginTop: '10px',
              paddingTop: 50,
              paddingBottom: 15,
            }}
          >
            {studentInfor && studentInfor.isPayFullTuition ? (
              <div className="tuition-notical">
                Học viên đã đóng đầy đủ học phí<br></br>
                Tiến hành tải ảnh xác nhận
              </div>
            ) : (
              <></>
            )}
            <Form.Item
              label="Ngày nộp tiền"
              rules={[{ required: true }]}
              name="dateSendMoney"
            >
              <DatePickerCustom
                style={{ width: '100%' }}
                placeholder="dd/mm/yy"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  setDateSendMoney(e.format('DD/MM/YYYY'));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Số tiền nộp (vnđ)"
              rules={[{ required: true, validator: validateMoneyField }]}
              name="totalMoney"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                }
                parser={(value) => value!.replace(/\./g, '')}
                onChange={(e) => {
                  setTotalMoney(e);
                }}
              />
            </Form.Item>
            <Form.Item label="Ảnh xác nhận nộp tiền" name="imgMoney">
              <UploadCustom
                setImageUrl={setImageUrl}
                fileList={fileList}
                setFileList={setFileList}
                classCustomAdd="upload-block-6-add"
                classCustomDetail="upload-block-6-add"
              ></UploadCustom>
            </Form.Item>
          </div>
          <div className="button-submit-container">
            <Button
              type="primary"
              className="button-submit"
              htmlType="submit"
              onClick={onHandleSend}
              disabled={buttonDisabled}
            >
              Xác nhận nộp
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default TuitionForm;
