import React from 'react';
import {
  Input,
  Button,
  Form,
  InputNumber,
  Select,
  message,
  Upload,
  Radio,
} from 'antd';
import { FieldTypeClass } from './type';
import { validateNameClass } from '@utils/Functions/validate';
import DatePickerCustom from '@components/DatePicker/datePicker';
import {
  DownOutlined,
  RightOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { Link } from 'react-router-dom';
import type { UploadProps } from 'antd/es/upload/interface';
import { getAccessTokenFromCookie } from '@utils/Functions/getToken';

const { RangePicker } = DatePickerCustom;
const { TextArea } = Input;
const { Option } = Select;

const ClassForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  collapsed1,
  collapsed2,
  toggleCollapse2,
  handleSelectCourse,
  dataCourse,
  cities,
  dataTeacher,
  setDataSchedual,
  dataSchedual,
  handleOnChangeDateSchedual,
  handleOnChangeSchedual,
  handleRemoveItemSchedual,
  handleAddSchedual,
  isDetail,
  isActive,
  setIsActive,
  handleDeleteClass,
}) => {
  const accessToken = getAccessTokenFromCookie();
  const props: UploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_BASE_URL}/api/v1/class/import-schedule`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        const response = info.file.response;
        setDataSchedual(response.data);
        if (response.statusCode === 200) {
          message.success(`${info.file.name} Đọc dữ liệu file thành công`);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Form
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="main-add-form"
      key={JSON.stringify(initialValues)}
    >
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Khóa học"
          name="course"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên khóa học!',
            },
          ]}
        >
          <Select
            placeholder="Chọn khóa học"
            style={{ width: '100%' }}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              searchIgnoreCaseAndAccent(
                input,
                option?.props.children?.toString() || '',
              )
            }
            onChange={(selectedValue) => handleSelectCourse(selectedValue)}
          >
            {dataCourse.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Tên lớp học"
          name="name"
          rules={[
            {
              required: true,
              validator: validateNameClass,
            },
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Khu vực"
          name="area"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập khu vực lớp học!',
            },
          ]}
        >
          <Select
            placeholder="Chọn khu vực"
            style={{ width: '100%' }}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              searchIgnoreCaseAndAccent(
                input,
                option?.props.children?.toString() || '',
              )
            }
          >
            {cities.map((city) => (
              <Option key={city.Id} value={city.Id}>
                {city.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Giảng viên"
          name="userTeacher"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn giảng viên!',
            },
          ]}
        >
          <Select
            placeholder="Chọn giảng viên"
            style={{ width: '100%' }}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              searchIgnoreCaseAndAccent(
                input,
                option?.props.children?.toString() || '',
              )
            }
          >
            {dataTeacher.map((item: any) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Thời gian học"
          name="date"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian học!',
            },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Thời gian thi giữa kỳ dự kiến"
          name="midtermExamDate"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian học!',
            },
          ]}
        >
          <DatePickerCustom
            style={{ width: '100%' }}
            placeholder={'Thi giữ kì dự kiến'}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Thời gian thi cuối kỳ dự kiến"
          name="semesterExamDate"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian học!',
            },
          ]}
        >
          <DatePickerCustom
            style={{ width: '100%' }}
            placeholder={'Thi cuối kì dự kiến'}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass>
          label="Số buổi"
          name="numberSessions"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn số buổi học!',
            },
          ]}
        >
          <InputNumber
            min={1}
            max={1000000000}
            style={{ width: '100%' }}
            value={120}
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeClass> label="Ghi chú" name="note">
          <TextArea rows={4} placeholder="" maxLength={600} />
        </Form.Item>
      )}
      <div className="main-left-block-title" onClick={() => toggleCollapse2()}>
        {collapsed2 ? (
          <RightOutlined
            className="main-left-block-title-icon"
            style={{ marginRight: 5 }}
          />
        ) : (
          <DownOutlined
            className="main-left-block-title-icon"
            style={{ marginRight: 5 }}
          />
        )}
        Tạo thời khóa biểu
      </div>
      {collapsed2 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button style={{ marginRight: 50 }}>
            <Link to="/academyTkb.xlsx" target="_blank">
              <DownloadOutlined style={{ marginRight: 5 }} /> Tải file mẫu
            </Link>
          </Button>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload file</Button>
          </Upload>
        </div>
      )}
      {collapsed2 && (
        <>
          <div className="schedual">
            <div className="schedual-header">
              <div className="schedual-header-1">Ngày</div>
              <div className="schedual-header-2">Buổi</div>
              <div className="schedual-header-3">Tên bài</div>
              <div className="schedual-header-4">Mô tả</div>
              <div className="schedual-header-5"></div>
            </div>
            {dataSchedual.map((data, index) => (
              <div className="schedual-body" key={index}>
                <div className="schedual-body-1">
                  <DatePickerCustom
                    style={{ borderRadius: 0 }}
                    value={data.ISODate ? moment.utc(data.ISODate) : null}
                    allowClear
                    format="DD/MM/YYYY"
                    onChange={(date) => handleOnChangeDateSchedual(index, date)}
                  />
                </div>
                <div className="schedual-body-2">
                  <InputNumber
                    style={{ borderRadius: 0 }}
                    value={data.Lesson}
                    onChange={(value) =>
                      handleOnChangeSchedual(index, 'Lesson', value)
                    }
                  />
                </div>
                <div className="schedual-body-3">
                  <Input
                    style={{ borderRadius: 0 }}
                    value={data.LessonName}
                    onChange={(e) =>
                      handleOnChangeSchedual(
                        index,
                        'LessonName',
                        e.target.value,
                      )
                    }
                  />
                </div>
                <div className="schedual-body-4">
                  <Input
                    style={{ borderRadius: 0 }}
                    value={data.Description}
                    onChange={(e) =>
                      handleOnChangeSchedual(
                        index,
                        'Description',
                        e.target.value,
                      )
                    }
                  />
                </div>
                <div className="schedual-body-5">
                  <Button
                    style={{ borderRadius: 0 }}
                    onClick={() => handleRemoveItemSchedual(index)}
                  >
                    <DeleteFilled />
                  </Button>
                </div>
              </div>
            ))}

            <div className="schedual-btn">
              <Button onClick={() => handleAddSchedual()}>
                <PlusCircleOutlined style={{ marginRight: 5 }} /> Thêm
              </Button>
            </div>
          </div>
          {isDetail ? (
            <>
              <Form.Item label="Trạng thái">
                <Radio.Group
                  style={{ marginLeft: 50 }}
                  value={isActive}
                  onChange={(event) => setIsActive(event.target.value)}
                >
                  <Radio value={true}>Hoạt động</Radio>
                  <Radio value={false} style={{ marginLeft: 30 }}>
                    Không hoạt động
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="button-submit-container">
                <Button
                  type="default"
                  className="button-submit"
                  onClick={handleDeleteClass}
                >
                  Xóa lớp học
                </Button>
                <Button
                  type="primary"
                  className="button-submit"
                  htmlType="submit"
                >
                  Xác nhận
                </Button>
              </div>
            </>
          ) : (
            <div className="button-submit-container">
              <Button
                type="primary"
                className="button-submit"
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};

export default ClassForm;
