import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, notification } from 'antd';
import FilterComponent from '@components/Filter';
import TabelComponent from '@components/Table';
import { handleCourse, handleClass } from '@services';
import '../../index.scss';
import { OPageIndex } from '@configs/pageSize';
import dataCity from '@configs/dataCity.json';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { breadcrumbListClass as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListClass as columns } from '@components/Table/Columns';
import { dataOptionListClass as dataOption } from '@components/Filter/dataOption';

const ListClass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [cities, setCities] = useState([]);
  const title = 'Danh sách lớp học';
  const titleButton = 'Tạo lớp học';
  const routerAdd = '/list-class/add';
  const idToNameMap = {};
  dataCity.forEach((city: any) => {
    idToNameMap[city.Id] = city.Name;
  });
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  useEffect(() => {
    setCities(dataCity);
    const myisActive = queryParams.get('isActive');
    if (myisActive) {
      if (myisActive === 'true') {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
      } else {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
      }
    }
    const myArea = queryParams.get('area');
    if (myArea) {
      const maxKey =
        dataFilter.length > 0
          ? Math.max(...dataFilter.map((item) => item.key))
          : 0;
      const listMyArea = myArea.split(',');
      const dataFilterArea = listMyArea.map((item, index) => {
        return {
          key: maxKey + index + 1,
          label: idToNameMap[item],
          field: 'area',
          value: item,
        };
      });
      setDataFilter([...dataFilter, ...dataFilterArea]);
    }
  }, []);
  useEffect(() => {
    const myText = queryParams.get('text') || '';
    setText(myText);
    getDataTable();
  }, [location.search]);

  const getDataTable = () => {
    const queryParams = new URLSearchParams(location.search);
    const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
    const myisActive = queryParams.get('isActive');
    const myArea = queryParams.get('area');
    const myText = queryParams.get('text') || '';
    setTableLoading(true);
    handleClass
      .GetList(mypageIndex, myisActive, myText, myArea)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data.listData);
          setCurrentPage(Number(data.pageIndex));
          setTotalItems(Number(data.totalRecord));
          setTableLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const showModalExcel = () => {
    setOpenExcel(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };
  const hideModalExcel = () => {
    setOpenExcel(false);
  };
  const handleDownloadExcel = async () => {
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    handleClass.downloadExcel(myisActive, myText);
    setOpenExcel(false);
  };
  const handleDeleteCourse = () => {
    const body = {
      isActive: false,
      isDelete: true,
    };
    handleCourse
      .updateStatus(modalData.code, body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Xóa thành công',
            description: 'Đã xóa dữ liệu khóa học này khỏi hệ thống !!',
          });
          setOpen(false);
          getDataTable();
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
          });
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (record: any) => {
    // console.log(record, 'record');
    navigate(`/list-class/detail?code=${record.code}`);
  };

  const handleGoAttendance = (record: any) => {
    console.log(record, 'record');
    navigate(`/list-class/attendance?code=${record.code}`);
  };

  const handleAddStudent = (record: any) => {
    console.log(record, 'record');
    navigate(`/list-class/add-to-class?code=${record.code}`);
  };
  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmitFilter = () => {
    setIsOpen(!isOpen);
    const params = new URLSearchParams(window.location.search);
    if (text) {
      params.set('text', text);
    } else {
      params.delete('text');
    }
    if (select1Value === 'isActive') {
      const dataFilterExits = dataFilter.filter((item) => item.key != 0);
      if (select2Value == 'true') {
        setDataFilter([
          ...dataFilterExits,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
        params.set('isActive', 'true');
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        navigate(newUrl);
      } else if (select2Value == 'false') {
        setDataFilter([
          ...dataFilterExits,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
        params.set('isActive', 'false');
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        navigate(newUrl);
      }
    }
    if (select1Value === 'area') {
      if (select2Value) {
        // Kiểm tra xem select2Value đã tồn tại trong dataFilter chưa
        const isValueExists = dataFilter.some(
          (item) => item.value === select2Value,
        );
        if (!isValueExists) {
          // Tìm max key hiện có trong dataFilter
          const maxKey =
            dataFilter.length > 0
              ? Math.max(...dataFilter.map((item) => item.key))
              : 0;

          // Thêm một giá trị mới với key tự tăng
          const newDataItem = {
            key: maxKey + 1,
            label: idToNameMap[select2Value],
            field: 'area',
            value: select2Value,
          };

          setDataFilter([...dataFilter, newDataItem]);
          if (params.has('area')) {
            const paramValue = params.get('area');
            const paramValues = paramValue.split(',');
            // Kiểm tra xem giá trị mới đã tồn tại trong danh sách hay chưa
            if (paramValues.includes(select2Value)) {
              // Nếu tồn tại k lam gi ca
              // const updatedValues = paramValues.filter(
              //   (val) => val !== select2Value,
              // );
              // if (updatedValues.length === 0) {
              //   params.delete('area');
              // } else {
              //   // Cập nhật tham số
              //   params.set('area', updatedValues.join(','));
              // }
            } else {
              // Nếu không tồn tại, thêm giá trị mới vào danh sách
              paramValues.push(select2Value);

              // Cập nhật tham số
              params.set('area', paramValues.join(','));
            }
          } else {
            // Nếu tham số chưa tồn tại, tạo một tham số mới với giá trị
            params.set('area', select2Value);
          }
        }
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        navigate(newUrl);
      }
    }
  };
  const handleChangeInput = (value) => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('text')) {
      params.set('text', value);
    } else {
      params.append('text', value);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const [dataFilter, setDataFilter] = useState([]);
  const handleDeleteFilter = (key, item) => {
    const updatedDataFilter = dataFilter.filter((item) => item.key !== key);
    setDataFilter(updatedDataFilter);
    const params = new URLSearchParams(window.location.search);
    if (params.has('isActive')) {
      params.delete('isActive');
    }
    if (item.field === 'area') {
      if (params.has('area')) {
        const paramValue = params.get('area');
        const paramValues = paramValue.split(',');
        // Kiểm tra xem giá trị mới đã tồn tại trong danh sách hay chưa
        if (paramValues.includes(item.value)) {
          // Nếu tồn tại, loại bỏ giá trị đó
          const updatedValues = paramValues.filter((val) => val !== item.value);
          if (updatedValues.length === 0) {
            params.delete('area');
          } else {
            // Cập nhật tham số
            params.set('area', updatedValues.join(','));
          }
        } else {
          // Nếu không tồn tại k lam gi ca
          // paramValues.push(item.value);
          // // Cập nhật tham số
          // params.set('area', paramValues.join(','));
        }
      } else {
        // Nếu tham số chưa tồn tại, tạo một tham số mới với giá trị
        params.set('area', item.value);
      }
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} lớp học</span>
    );
  };
  return (
    <>
      <Modal
        title="Xóa Lớp học"
        open={open}
        onOk={() => handleDeleteCourse()}
        onCancel={() => hideModal()}
        footer={
          modalData?.canDelete ? (
            <div>
              {/* Nội dung của footer */}
              <Button onClick={() => handleDeleteCourse()} type="primary">
                Xác nhận
              </Button>
              <Button onClick={() => hideModal()}>Hủy bỏ</Button>
            </div>
          ) : null
        }
      >
        {modalData && (
          <>
            {modalData.canDelete ? (
              <p>
                Bạn đồng ý xóa dữ liệu lớp học <strong>{modalData.name}</strong>{' '}
                khỏi hệ thống ?
              </p>
            ) : (
              <p>
                Xin lỗi, bạn không thể xóa dữ liệu khóa học này. Vui lòng xóa
                toàn bộ các học viên trong lớp học này trước !!
              </p>
            )}
          </>
        )}
      </Modal>
      <Modal
        title="Tải dữ liệu lớp học"
        open={openExcel}
        onOk={() => handleDownloadExcel()}
        onCancel={() => hideModalExcel()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận tải xuống dữ liệu <strong>{totalItems} lớp học</strong> ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterComponent
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        setText={setText}
        text={text}
        handleChangeInput={handleChangeInput}
        showModalExcel={showModalExcel}
        dataFilter={dataFilter}
        handleDeleteFilter={handleDeleteFilter}
        handleSelect1Change={handleSelect1Change}
        select1Value={select1Value}
        searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
        dataOption={dataOption}
        select2Value={select2Value}
        setSelect2Value={setSelect2Value}
        handleSubmitFilter={handleSubmitFilter}
        placeholderInput={'Tìm kiếm theo tên lớp học, tên khóa học'}
      />
      <TabelComponent
        columns={columns({
          cities,
          handleGoAttendance,
          handleEdit,
          handleAddStudent,
        })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </>
  );
};

export default ListClass;
