import React, { useState } from 'react';
import {
  validatePassword,
  validateUsername,
} from '../../utils/Functions/validate';
import { Button, Form, Input, notification } from 'antd';
import {
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { handleAuth } from '../../services';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import './login.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [error, setError] = useState<number | null>(null);
  const [accessToken, setAccessToken] = useCookies(['accessToken']);
  const [refreshToken, setRefreshToken] = useCookies(['refreshToken']);
  console.log(accessToken, refreshToken);
  type FieldType = {
    userName?: string;
    password?: string;
    remember?: string;
  };

  const onFinish = (values: any) => {
    handleAuth
      .Login(values.userName, values.password)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setAccessToken('accessToken', data.accessToken);
          setRefreshToken('refreshToken', data.refreshToken);
          navigate('/');
        } else if (statusCode === 403) {
          setError(null);
          notification.error({
            message: 'Lỗi',
            description: 'Tài khoản hoặc mật khẩu không chính xác !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <div className="login-page">
      <div className="login-form p-6 flex flex-col items-center">
        <a href={`/`}>
          <img className="logo" src="/images/ic_logo_white.png" alt="30Shine" />
        </a>

        <Form
          autoComplete="off"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="w-full mt-8 form"
        >
          <div className="my-5">
            <div className="relative">
              <Form.Item<FieldType>
                label=""
                name="userName"
                rules={[
                  { required: true, message: 'Làm ơn điền tên tài khoản!' },
                  { validator: (_, value) => validateUsername(value) },
                ]}
              >
                <Input
                  className="relative-input"
                  placeholder="Tên đăng nhập ..."
                />
              </Form.Item>
              <UserOutlined className="relative-icon" />
            </div>
          </div>
          <div className="my-5">
            <div className="relative">
              <Form.Item<FieldType>
                label=""
                name="password"
                rules={[
                  { required: true, message: 'Làm ơn nhập mật khẩu!' },
                  { validator: (_, value) => validatePassword(value) },
                ]}
              >
                <Input
                  type={isShowPassword ? 'text' : 'password'}
                  className="relative-input"
                  placeholder="Mật khẩu ..."
                />
              </Form.Item>
              {isShowPassword ? (
                <EyeInvisibleOutlined
                  onClick={handleShowPassword}
                  className="relative-icon"
                />
              ) : (
                <EyeOutlined
                  onClick={handleShowPassword}
                  className="relative-icon"
                />
              )}
            </div>
          </div>
          <div className="">
            {error == 1 ? (
              <a className="text-red-400 text-sm" href={`/forgot-password`}>
                <p>Bạn nhập sai tên đăng nhập hoặc mật khẩu. </p>
                <strong>Quên mật khẩu?</strong>
              </a>
            ) : (
              <a className="text-slate-400 text-sm" href={`/forgot-password`}>
                Quên mật khẩu?
              </a>
            )}
          </div>
          <div>
            <input type="hidden" value={'true'} name="redirect" />
          </div>

          <Form.Item>
            <Button htmlType="submit" className="button">
              ĐĂNG NHẬP
            </Button>
          </Form.Item>
        </Form>

        <div className="mt-5 p-3">
          <p className="text-gray-500 text-sm text-justify"></p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
