import React from 'react';
import { Button, Image } from 'antd';
import { DataType, ColumnsRendererProps } from './typeTabel';
import { mapCityNameByKey } from '@utils/Functions/handleCity';
import { formatNumberWithCommas } from '@utils/Functions/IgnoreCase';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

export const ColumnsListStudent = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const columns = [
    {
      title: 'Mã học viên',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên học viên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SĐT học viên',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'SĐT phụ huynh',
      dataIndex: 'parentPhone',
      key: 'parentPhone',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => (
        <>{isActive ? 'Hoạt động' : 'Không hoạt động'}</>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListClassTeacher = ({
  handleEdit,
  handleGoAttendance,
  cities,
}) => {
  const columns = [
    {
      title: 'Tên lớp học/ tên khóa học',
      key: 'name',
      render: (_, record) => (
        <>
          <div>{record.name}</div>
          <div>{record.course}</div>
        </>
      ),
    },
    {
      title: 'Khu vực',
      key: 'area',
      render: (_, record) => <>{mapCityNameByKey(cities, record.area)}</>,
    },
    {
      title: 'Học phí',
      dataIndex: 'tuitionFee',
      key: 'tuitionFee',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Số học viên',
      dataIndex: 'totalStudent',
      key: 'totalStudent',
    },
    {
      title: 'Học phí đã đóng',
      dataIndex: 'tuitionFeeStudent',
      key: 'tuitionFeeStudent',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Tổng học phí',
      dataIndex: 'totalTuitionFee',
      key: 'totalTuitionFee',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div>
          <div
            onClick={() => handleGoAttendance(record)}
            className="action-custom-btn"
          >
            Điểm danh
          </div>
          <div
            onClick={() => handleEdit(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Xem danh sách
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListStudentClassTeacher = ({ handlePayTuition }) => {
  const columns = [
    {
      title: 'Mã học viên',
      dataIndex: 'code',
      key: 'code',
      render: (text) => (
        <Link to={`/student-class/detail-student/${text}`}>{text}</Link>
      ),
    },
    {
      title: 'Tên học viên',
      key: 'name',
      render: (_, record) => (
        <Link to={`/student-class/detail-student/${record.code}`}>
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Ngày nhập học',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Số buổi đã học',
      dataIndex: 'attendanceNumber',
      key: 'attendanceNumber',
    },
    {
      title: 'Số đầu thực hành',
      dataIndex: 'practiceShotsNumber',
      key: 'practiceShotsNumber',
    },
    {
      title: 'Số tiền đã nộp',
      dataIndex: 'tuitionFeeStudent',
      key: 'tuitionFeeStudent',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div>
          <div
            onClick={() => handlePayTuition(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Xem nộp tiền
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListCourse = ({
  handleAddClass,
  handleEdit,
  handleDelete,
}) => {
  const columns = [
    {
      title: 'Mã khóa học',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên khóa học',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Học phí',
      dataIndex: 'totalTuitionFee',
      key: 'totalTuitionFee',
      render: (text) => <p>{formatNumberWithCommas(text)}</p>,
    },
    {
      title: 'Số buổi học',
      dataIndex: 'numberSessions',
      key: 'numberSessions',
    },
    {
      title: 'Số lớp học',
      dataIndex: 'totalClass',
      key: 'totalClass',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => <p>{isActive ? 'Hoạt động' : 'Không hoạt động'}</p>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div>
          <div
            onClick={() => handleAddClass(record)}
            className="action-custom-btn"
          >
            Tạo lớp học
          </div>
          <div
            onClick={() => handleEdit(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Chỉnh sửa khóa học
          </div>
          <div
            onClick={() => handleDelete(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Xóa khóa học
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListClass = ({
  cities,
  handleGoAttendance,
  handleEdit,
  handleAddStudent,
}) => {
  const columns = [
    {
      title: 'Tên lớp học/ tên khóa học',
      key: 'name',
      render: (_, record) => (
        <Link to={`/list-class/studen-class?code=${record.code}`}>
          <div>{record.name}</div>
          <div>{record.course}</div>
        </Link>
      ),
    },
    {
      title: 'Khu vực',
      key: 'area',
      render: (_, record) => <>{mapCityNameByKey(cities, record.area)}</>,
    },
    {
      title: 'Học phí',
      dataIndex: 'tuitionFee',
      key: 'tuitionFee',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Số học viên',
      dataIndex: 'totalStudent',
      key: 'totalStudent',
    },
    {
      title: 'Học phí đã đóng',
      dataIndex: 'tuitionFeeStudent',
      key: 'tuitionFeeStudent',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Tổng học phí',
      dataIndex: 'totalTuitionFee',
      key: 'totalTuitionFee',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div>
          <div
            onClick={() => handleGoAttendance(record)}
            className="action-custom-btn"
          >
            Điểm danh
          </div>
          <div
            onClick={() => handleEdit(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Xem danh sách
          </div>
          <div
            onClick={() => handleAddStudent(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Thêm học viên
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListStudentClass = ({
  numberSessions,
  numberExercises,
  handleGoMarkExam,
  handlePayTuition,
}) => {
  const timezone = 'Asia/Ho_Chi_Minh';
  const columns = [
    {
      title: 'Mã học viên',
      dataIndex: 'code',
      key: 'code',
      render: (text) => <Link to={`/list-students/${text}`}>{text}</Link>,
    },
    {
      title: 'Tên học viên',
      key: 'name',
      render: (_, record) => (
        <Link to={`/list-students/${record.code}`}>{record.name}</Link>
      ),
    },
    {
      title: 'Ngày nhập học',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <>{moment.tz(text, timezone).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Số buổi đã học',
      dataIndex: 'attendanceNumber',
      key: 'attendanceNumber',
      render: (text) => (
        <>
          {text}/{numberSessions}
        </>
      ),
    },
    {
      title: 'Số đầu thực hành',
      dataIndex: 'practiceShotsNumber',
      key: 'practiceShotsNumber',
      render: (text) => (
        <>
          {text}/{numberExercises}
        </>
      ),
    },
    {
      title: 'Số tiền đã nộp',
      dataIndex: 'tuitionFeeStudent',
      key: 'tuitionFeeStudent',
      render: (text) => <>{formatNumberWithCommas(text)}</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div>
          <div
            onClick={() => handleGoMarkExam(record)}
            className="action-custom-btn"
          >
            Chấm điểm
          </div>
          <div
            onClick={() => handlePayTuition(record)}
            className="action-custom-btn"
            style={{ marginTop: 6 }}
          >
            Nộp thêm tiền
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsAddStudentClass = ({}) => {
  const columns = [
    {
      title: 'Mã học viên',
      dataIndex: 'code',
      key: 'code',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Tên học viên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SĐT học viên',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'SĐT phụ huynh',
      dataIndex: 'parentPhone',
      key: 'parentPhone',
    },
    {
      title: 'Lớp học',
      dataIndex: 'address',
      key: 'address',
      render: () => <a>Chưa có lớp học</a>,
    },
  ];

  return columns;
};

export const ColumnsExam = ({ onHandleSelected }) => {
  const columns = [
    {
      title: 'Kiểu hình học',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Ảnh minh họa',
      dataIndex: 'imgDemo',
      key: 'imgDemo',
      render: (_, record) => <img src={record.imgDemo} width={240}></img>,
    },
    {
      title: 'Ảnh bài thi',
      dataIndex: 'photo',
      key: 'photo',
      render: (_, record) => <Image src={record.photo} height={120}></Image>,
    },
    {
      title: 'Chấm',
      key: 'value',
      render: (_, record) => (
        <div>
          <Button
            onClick={() => {
              record.value === true
                ? (record.value = null)
                : (record.value = true);
              onHandleSelected(record);
            }}
            // disabled={record.value !== null}
            style={{
              border:
                record.value === null
                  ? '1px solid rgb(32, 134, 255)'
                  : record.value
                  ? '1px solid rgb(120, 181, 0)'
                  : '1px solid rgb(128,128,128)',
              color:
                record.value === null
                  ? 'rgb(32, 134, 255)'
                  : record.value
                  ? 'rgb(120, 181, 0)'
                  : 'rgb(128,128,128)',
            }}
          >
            Đạt
          </Button>
          <Button
            onClick={() => {
              record.value === false
                ? (record.value = null)
                : (record.value = false);
              onHandleSelected(record);
            }}
            // disabled={record.value !== null}
            style={{
              border:
                record.value === null
                  ? '1px solid rgb(32, 134, 255)'
                  : !record.value
                  ? '1px solid red'
                  : '1px solid rgb(128,128,128)',
              color:
                record.value === null
                  ? 'rgb(32, 134, 255)'
                  : !record.value
                  ? 'red'
                  : 'rgb(128,128,128)',
              marginLeft: '5px',
            }}
          >
            Không đạt
          </Button>
        </div>
      ),
    },
  ];

  return columns;
};
