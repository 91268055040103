// MainRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgotPassword';
import Page404 from '../pages/Errors/404';
import Page403 from '../pages/Errors/403';
import Page500 from '../pages/Errors/500';
import StudentManagement from '../pages/StudentManagement';
import AddStudent from '../pages/StudentManagement/add';
import DetailStudent from '../pages/StudentManagement/detail';
import ListCourses from '../pages/Courses/ListCourses/ListCourses';
import AddCourses from '../pages/Courses/ListCourses/add';
import DetailCourses from '../pages/Courses/ListCourses/detailt';
import ListClass from '../pages/Courses/ListClass/ListClass';
import AddClass from '../pages/Courses/ListClass/add';
import Attendance from '../pages/Courses/ListClass/attendance';
import AddStudentToClass from '../pages/Courses/ListClass/addStudent';
import StudentClass from '../pages/Courses/ListClass/listStudentClass';
import DetailClass from '../pages/Courses/ListClass/detailClass';
import DetailClassTeacher from '../pages/ClassStudent/ListStudentClass';
import AttendanceTeacher from '../pages/ClassStudent/attendance';
import DetailStudentTeacher from '../pages/ClassStudent/detailStudent';
import ExamPages from '../pages/exam/index';
import TuitionPages from '../pages/tuition/index';
import TuitionTeacher from '../pages/ClassStudent/tuition';
import ClassStudent from '../pages/ClassStudent';
import PrivateRoute from './PrivateRoute';
import HomePage from '../pages/index';

const MainRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Sử dụng PrivateRoute cho các routes của MainRouter */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-students"
          element={
            <PrivateRoute>
              <StudentManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-students/add"
          element={
            <PrivateRoute>
              <AddStudent />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-students/:id"
          element={
            <PrivateRoute>
              <DetailStudent />
            </PrivateRoute>
          }
        />

        <Route
          path="/list-courses"
          element={
            <PrivateRoute>
              <ListCourses />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-courses/add"
          element={
            <PrivateRoute>
              <AddCourses />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-courses/detail"
          element={
            <PrivateRoute>
              <DetailCourses />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-class"
          element={
            <PrivateRoute>
              <ListClass />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-class/add"
          element={
            <PrivateRoute>
              <AddClass />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-class/detail"
          element={
            <PrivateRoute>
              <StudentClass />
            </PrivateRoute>
          }
        />

        <Route
          path="/list-class/studen-class"
          element={
            <PrivateRoute>
              <DetailClass />
            </PrivateRoute>
          }
        />

        <Route
          path="/list-class/attendance"
          element={
            <PrivateRoute>
              <Attendance />
            </PrivateRoute>
          }
        />
        <Route
          path="/student-class/attendance"
          element={
            <PrivateRoute>
              <AttendanceTeacher />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-class/add-to-class"
          element={
            <PrivateRoute>
              <AddStudentToClass />
            </PrivateRoute>
          }
        />

        <Route
          path="/student-class"
          element={
            <PrivateRoute>
              <ClassStudent />
            </PrivateRoute>
          }
        />
        <Route
          path="/student-class/detail-student/:id"
          element={
            <PrivateRoute>
              <DetailStudentTeacher />
            </PrivateRoute>
          }
        />
        {/* đóng học phí và chấm thi */}
        <Route
          path="/mark-exam"
          element={
            <PrivateRoute>
              <ExamPages />
            </PrivateRoute>
          }
        />

        <Route
          path="/pay-tuition"
          element={
            <PrivateRoute>
              <TuitionPages />
            </PrivateRoute>
          }
        />
        {/* // router thầy giáo */}
        <Route
          path="/class/details-list"
          element={
            <PrivateRoute>
              <DetailClassTeacher />
            </PrivateRoute>
          }
        />
        <Route
          path="/tuition/details"
          element={
            <PrivateRoute>
              <TuitionTeacher />
            </PrivateRoute>
          }
        />

        <Route
          path="/403"
          element={
            <PrivateRoute>
              <Page403 />
            </PrivateRoute>
          }
        />

        <Route
          path="/500"
          element={
            <PrivateRoute>
              <Page500 />
            </PrivateRoute>
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Page404 />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default MainRouter;
