const searchIgnoreCaseAndAccent = (searchText: string, optionText: string) => {
  const normalizedSearch = searchText
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const normalizedOption = optionText
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return normalizedOption.includes(normalizedSearch);
};

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export { searchIgnoreCaseAndAccent, formatNumberWithCommas };
