// src/store/userSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { handleUser } from '../../services';

interface User {
  userName: string;
  role: string;
}

interface UserState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

// Thunk action để gửi request GET tới server với token để lấy thông tin người dùng
export const fetchUserInfo = createAsyncThunk<User, void, { state: RootState }>(
  'user/fetchUserInfo',
  async () => {
    const response: any = await handleUser.Check();
    const { statusCode, message, data } = response;
    // console.log(response);
    if (statusCode === 200) {
      return data;
    } else if (statusCode === 403) {
      return null; // Hoặc giá trị khác thích hợp nếu cần
    }

    throw new Error(message);
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Xử lý các action liên quan tới việc lấy thông tin người dùng thành công hoặc thất bại
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ?? 'Failed to fetch user information';
      });
  },
});

export default userSlice.reducer;
