import { BrowserRouter as Router } from 'react-router-dom';
import MainRouter from '@routes/MainRouter';
import '@styles/globals.scss';

const App: React.FC = () => {
  return (
    <Router>
      <MainRouter />
    </Router>
  );
};

export default App;
