import React from 'react';
import { Input, Button, Form, InputNumber, Radio } from 'antd';
import { FieldTypeCourses } from './type';
import {
  validateNameCourse,
  validateNameStudyFlow,
} from '@utils/Functions/validate';
import DatePickerCustom from '@components/DatePicker/datePicker';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const { RangePicker } = DatePickerCustom;
const { TextArea } = Input;

const CourseForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  collapsed1,
  collapsed2,
  toggleCollapse2,
  isDetail,
}) => {
  return (
    <Form
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="main-add-form"
    >
      {collapsed1 && (
        <Form.Item<FieldTypeCourses>
          label="Tên khóa học"
          name="name"
          rules={[
            {
              required: true,
              validator: validateNameCourse,
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeCourses>
          label="Học phí"
          name="tuition"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập học phí!',
            },
          ]}
        >
          <InputNumber
            // min={1}
            // max={1000000000}
            style={{ width: '100%' }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }
            parser={(value) => value!.replace(/\./g, '')}
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeCourses>
          label="Thời gian học"
          name="date"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian cho khóa học!',
            },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeCourses>
          label="Số buổi học"
          name="sessionsNumber"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số buổi học!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed1 && (
        <Form.Item<FieldTypeCourses> label="Ghi chú" name="note">
          <TextArea rows={4} placeholder="" maxLength={600} />
        </Form.Item>
      )}
      <div className="main-left-block-title" onClick={() => toggleCollapse2()}>
        {collapsed2 ? (
          <RightOutlined
            className="main-left-block-title-icon"
            style={{ marginRight: 5 }}
          />
        ) : (
          <DownOutlined
            className="main-left-block-title-icon"
            style={{ marginRight: 5 }}
          />
        )}
        Lộ trình học
      </div>
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Tên giai đoạn"
          name={['flow1', 'name']}
          rules={[
            {
              required: true,
              validator: validateNameStudyFlow,
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Thời gian học"
          name={['flow1', 'date']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian học cho giai đoạn!',
            },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Tổng số buổi học"
          name={['flow1', 'numberSessions']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tổng số buổi học trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Số buổi nghỉ tối đa"
          name={['flow1', 'maximumDaysOff']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số buổi nghỉ tối đa trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Số đầu thực hành"
          name={['flow1', 'numberExercises']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số đầu cắt thực hành trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses> label="Ghi chú" name={['flow1', 'note']}>
          <TextArea rows={4} placeholder="" maxLength={600} />
        </Form.Item>
      )}
      {collapsed2 && <div className="main-left-block-wall"></div>}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Tên giai đoạn"
          name={['flow2', 'name']}
          rules={[
            {
              required: true,
              validator: validateNameStudyFlow,
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Thời gian học"
          name={['flow2', 'date']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thời gian học cho giai đoạn!',
            },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Tổng số buổi học"
          name={['flow2', 'numberSessions']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tổng số buổi học trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Số buổi nghỉ tối đa"
          name={['flow2', 'maximumDaysOff']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số buổi nghỉ tối đa trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses>
          label="Số đầu thực hành"
          name={['flow2', 'numberExercises']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số đầu cắt thực hành trong giai đoạn!',
            },
          ]}
        >
          <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {collapsed2 && (
        <Form.Item<FieldTypeCourses> label="Ghi chú" name={['flow2', 'note']}>
          <TextArea rows={4} placeholder="" maxLength={600} />
        </Form.Item>
      )}
      {isDetail ? (
        <>
          <Form.Item<FieldTypeCourses> label="Trạng thái" name="is_active">
            <Radio.Group>
              <Radio value={true}>Hoạt động</Radio>
              <Radio value={false}>Không hoạt động</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="button-submit-container">
            <Button type="primary" className="button-submit" htmlType="submit">
              Cập nhật dữ liệu
            </Button>
          </div>
        </>
      ) : (
        <div className="button-submit-container">
          <Button type="primary" className="button-submit" htmlType="submit">
            Xác nhận
          </Button>
        </div>
      )}
    </Form>
  );
};

export default CourseForm;
