import { axiosInstanceAuthorization } from '../Config/AxiosInterceptor';
import { OPageSize } from '../../configs/pageSize';

const getListCourse = (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/courses/list-courses?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';

  return axiosInstanceAuthorization.get(url);
};

const statusCourse = (id: string, body: any): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/delete/${id}`,
    body,
  );
};

const createCourse = (body: any): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/create`,
    body,
  );
};

const getDetailCourse = (id: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/${id}/detail`,
  );
};

const updateCourse = (id: string, body: any): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/update/${id}`,
    body,
  );
};

export default {
  getListCourse,
  statusCourse,
  createCourse,
  updateCourse,
  getDetailCourse,
};
