import ClassEndpoint from '../../api/listApi/class';
import axios from 'axios';
import Cookies from 'js-cookie';

const Create = async (body: any): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.createClass(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const GetList = async (
  pageIndex: string,
  isActive: string,
  title: string,
  myArea: string,
): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.getListClass(
      pageIndex,
      isActive,
      title,
      myArea,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const GetListClassTeacher = async (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.getListClassTeacher(
      pageIndex,
      isActive,
      title,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken'); // Thay đổi key này nếu cần
};

const downloadExcel = async (isActive: string, title: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie();
  let url = process.env.REACT_APP_BASE_URL + `/api/v1/class/list-class/export?`;

  if (isActive) {
    url += `isActive=${isActive}`;
  }
  if (title) {
    url += isActive ? `&title=${title}` : `title=${title}`;
  }
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'khoahoc_30shineAcademy.xlsx';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error);
    });
};

const getCourseSelector = async (): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.listCourseSelector();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const getListTeacher = async (): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.listTeacher();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const detailClass = async (code: string): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.detailClass(code);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const getQRClass = async (code: string): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.getQRClass(code);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const addStudentToClass = async (body: any): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.addStudentToClass(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const updateClass = async (code: string, body: any): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.updateClass(code, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const deleteClass = async (code: string, body: any): Promise<any | Error> => {
  try {
    const response = await ClassEndpoint.deleteClass(code, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default {
  GetList,
  downloadExcel,
  getCourseSelector,
  getListTeacher,
  detailClass,
  getQRClass,
  addStudentToClass,
  Create,
  GetListClassTeacher,
  updateClass,
  deleteClass,
};
