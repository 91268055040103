import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { Form, notification, Spin, Image, InputNumber } from 'antd';
import TuitionFee from '@api/listApi/tuitionFee';
import moment from 'moment';
import DatePickerCustom from '@components/DatePicker/datePicker';
import { breadcrumbTuitionTeacher as breadcrumbItems } from '@components/Breadcrumb';
import '../index.scss';

const TuitionTeacher = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [studentInfor, setStudentInfor] = useState<any>();
  const [studentInforData, setStudentInforData] = useState<any[]>();
  const [lastPayTuition, setLastPayTuition] = useState<any>();
  const idParam = urlSearchParams.get('id');
  const [imgDemo, setImgDemo] = useState<string>();

  const getDataTable = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await TuitionFee.getDataTuitionFee(id);
      setStudentInfor(response.data.data);
      setIsLoading(false);
      if (response.data.data && response.data.data.tuitionData.length > 0) {
        setStudentInforData(response.data.data.tuitionData.reverse());
        setLastPayTuition(response.data.data.tuitionData[0]);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Thất bại',
        description: 'Vui lòng thử lại.',
      });
      setIsLoading(false);
    }
  };
  const title = 'Nộp thêm học phí';
  const titleButton = '';
  const routerAdd = '';
  useEffect(() => {
    if (idParam) {
      getDataTable(idParam);
    }
  }, []);
  const [visible, setVisible] = useState(false);
  const scaleStep = 0.5;
  return (
    <>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={imgDemo}
        preview={{
          visible,
          scaleStep,
          src: imgDemo,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <TitleBox
          breadcrumbItems={breadcrumbItems(studentInfor)}
          title={title}
          titleButton={titleButton}
          routerAdd={routerAdd}
        />
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '75%',
              marginTop: '10px',
              height: 'auto',
            }}
          >
            {lastPayTuition ? (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
                className="main-add-form"
                style={{ marginTop: '5px' }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    height: '100%',
                    marginBottom: 15,
                    padding: '10px 0',
                  }}
                >
                  <Form.Item
                    label="Tên học viên - ID"
                    name="userName"
                    style={{ margin: 0 }}
                  >
                    <p style={{ marginTop: '5px', fontWeight: 600 }}>
                      {studentInfor &&
                        `${studentInfor.name} - ${studentInfor.code}`}
                    </p>
                  </Form.Item>
                  <Form.Item
                    label="Ngày nhập học"
                    name="name"
                    style={{ margin: 0 }}
                  >
                    <p style={{ marginTop: '5px', fontWeight: 600 }}>
                      {studentInfor &&
                        moment.utc(studentInfor.startDate).format('DD/MM/yyyy')}
                    </p>
                  </Form.Item>
                  <Form.Item
                    label="Khóa học"
                    name="identificationNumber"
                    style={{ margin: 0 }}
                  >
                    <p style={{ marginTop: '5px', fontWeight: 600 }}>
                      {studentInfor && studentInfor.courseName}
                    </p>
                  </Form.Item>
                  <Form.Item
                    label="Lớp học"
                    name=""
                    style={{ margin: 0, fontWeight: 600 }}
                  >
                    <p style={{ marginTop: '5px' }}>
                      {studentInfor && studentInfor.className}
                    </p>
                  </Form.Item>
                </div>

                <div
                  style={{
                    backgroundColor: 'white',
                    marginTop: '10px',
                    paddingTop: 20,
                    paddingBottom: 15,
                  }}
                  className="tuition-payment"
                >
                  <p style={{ marginLeft: '10%', fontWeight: 600 }}>
                    Lịch sử nộp tiền gần nhất
                  </p>
                  <Form.Item label="Ngày nộp tiền">
                    <DatePickerCustom
                      style={{ width: '100%' }}
                      value={moment.utc(lastPayTuition.date)}
                      placeholder="dd/mm/yy"
                      format={'DD/MM/YYYY'}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Số tiền nộp (vnđ)">
                    <InputNumber
                      style={{ width: '100%' }}
                      value={lastPayTuition.money}
                      disabled
                      formatter={(value) =>
                        `${value} VNĐ`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                      }
                      parser={(value) => value!.replace(/\./g, '')}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ảnh xác nhận nộp tiền"
                    rules={[{ required: true }]}
                    name="imgMoney"
                  >
                    {lastPayTuition.photo ? (
                      <Image
                        src={lastPayTuition.photo}
                        alt="avatar"
                        className="tuition-payment-image-preview"
                      />
                    ) : (
                      <></>
                    )}
                  </Form.Item>
                </div>
              </Form>
            ) : (
              <div className="tuition-no-payment">
                <img
                  src="/images/tuition.png"
                  className="tuition-no-payment-img"
                />
                <p className="tuition-no-payment-text">
                  Học viên chưa có lịch sử đóng học phí !
                </p>
              </div>
            )}
          </div>
          <div
            style={{
              padding: '10px',
              backgroundColor: 'white',
              marginTop: '15px',
              height: 'auto',
              marginLeft: '10px',
              width: '25%',
            }}
          >
            <div style={{ borderBottom: 'solid 1px rgb(220,220,220)' }}>
              <h4>Lịch sử nộp tiền</h4>
            </div>
            <div
              style={{
                display: 'flex',
                borderBottom: 'solid 1px rgb(220,220,220)',
              }}
            >
              <h4 style={{ width: '250px' }}>Tổng số tiền đã nộp: </h4>
              <h4>
                {studentInfor &&
                  new Intl.NumberFormat('en-US').format(
                    studentInfor.totalTuition,
                  )}
                vnđ
              </h4>
            </div>
            {studentInforData &&
              studentInforData.map((item, index) => (
                <div style={{ display: 'flex', marginTop: '15px' }} key={index}>
                  <div
                    style={{
                      width: '20%',
                      textAlign: 'center',
                      marginTop: '-20px',
                    }}
                  >
                    <p>{`Lần ${studentInfor.tuitionData.length - index}`}</p>
                    <div
                      style={{
                        height: '75px',
                        width: '3px',
                        backgroundColor: ' rgba(0,0,0,1)',
                        marginLeft: '49%',
                        marginTop: '-10px',
                        borderRadius: '8px',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      width: '80%',
                      borderRadius: '5px',
                      border: 'solid 1px rgba(0,0,0,0.2)',
                      borderLeft: 'solid 4px rgba(0,0,0, 0.4)',
                      height: '75px',
                      lineHeight: '7px',
                      paddingLeft: '5px',
                    }}
                  >
                    <p>
                      Ngày nộp tiền:{' '}
                      <strong>{moment(item.date).format('DD/MM/yyyy')}</strong>
                    </p>
                    <p>
                      Số tiền nộp:{' '}
                      <strong>
                        {new Intl.NumberFormat('en-US').format(item.money)} VNĐ
                      </strong>
                    </p>
                    <p
                      style={{ color: '#1890FF' }}
                      className="tuition-payment-text"
                      onClick={() => {
                        setImgDemo(item.photo);
                        setVisible(true);
                      }}
                    >
                      Xem ảnh nộp tiền
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default TuitionTeacher;
