import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

// Tạo một instance mới của Axios với cấu hình interceptor
const axiosInstanceAuthorization: AxiosInstance = axios.create();

// Hàm lấy accessToken từ cookie
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken'); // Thay đổi key này nếu cần
};

// Interceptor để gửi accessToken trong header 'Authorization'
axiosInstanceAuthorization.interceptors.request.use(
  (config) => {
    const accessToken = getAccessTokenFromCookie();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    // config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// Interceptor để xử lý refreshToken khi trả về mã lỗi 403
axiosInstanceAuthorization.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // console.log('oke');
      const accessToken = Cookies.get('accessToken');
      const refreshToken = Cookies.get('refreshToken'); // Thay đổi key này nếu cần
      if (!refreshToken) {
        return Promise.reject('Refresh token has not instance');
      }

      try {
        // put đến api lấy token mới
        await axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/auth/refresh-token`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                accesstoken: accessToken,
                refreshtoken: refreshToken,
              },
            },
          )
          .then((response) => {
            if (response.data?.statusCode === 201) {
              // Lưu lại token mới nhận được vào cookie
              Cookies.set('accessToken', response.data.data.accessToken);
              Cookies.set('refreshToken', response.data.data.refreshToken);
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });

        // Gọi lại yêu cầu ban đầu với accessToken mới
        return axiosInstanceAuthorization(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

// axios upload ảnh
const axiosInstanceAuthorizationUpload: AxiosInstance = axios.create();
axiosInstanceAuthorizationUpload.interceptors.request.use(
  (config) => {
    const accessToken = getAccessTokenFromCookie();
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const axiosUploadToS3: AxiosInstance = axios.create();

axiosUploadToS3.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'Content-Type": "image/gif';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// axios tải file excel
const axiosInstanceAuthorizationExcel: AxiosInstance = axios.create();

axiosInstanceAuthorizationExcel.interceptors.request.use(
  (config) => {
    const accessToken = getAccessTokenFromCookie();
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// với các request không cần token
const axiosInstanceClient: AxiosInstance = axios.create();

// Thêm interceptor trước mỗi request được gửi đi
axiosInstanceClient.interceptors.request.use((config) => {
  config.headers['Content-type'] = 'application/json';
  return config;
});

export {
  axiosInstanceAuthorization,
  axiosInstanceClient,
  axiosInstanceAuthorizationUpload,
  axiosInstanceAuthorizationExcel,
  axiosUploadToS3,
};
