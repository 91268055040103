import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleStudent } from '@services';
import { OPageIndex } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import FilterComponent from '@components/Filter';
import TabelComponent from '@components/Table';
import { dataOptionListStudent as dataOption } from '@components/Filter/dataOption';
import { breadcrumbListStudent as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListStudent as columns } from '@components/Table/Columns';
import '../index.scss';

const StudentManagement = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách học viên';
  const titleButton = 'Thêm mới học viên';
  const routerAdd = '/list-students/add';

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  useEffect(() => {
    const myisActive = queryParams.get('isActive');
    if (myisActive) {
      if (myisActive === 'true') {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái đang hoạt động',
            field: 'isActive',
            value: true,
          },
        ]);
      } else {
        setDataFilter([
          ...dataFilter,
          {
            key: 0,
            label: 'Trạng thái không hoạt động',
            field: 'isActive',
            value: false,
          },
        ]);
      }
    }
  }, []);
  useEffect(() => {
    const myText = queryParams.get('text') || '';
    setText(myText);
    getDataTable();
  }, [location.search]);
  const getDataTable = () => {
    const queryParams = new URLSearchParams(location.search);
    const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    setTableLoading(true);
    handleStudent
      .GetList(mypageIndex, myisActive, myText)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data.listData);
          setCurrentPage(Number(data.pageIndex));
          setTotalItems(Number(data.totalRecord));
          setTableLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };
  const showModalExcel = () => {
    setOpenExcel(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };
  const hideModalExcel = () => {
    setOpenExcel(false);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/list-students/${record.code}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleDownloadExcel = async () => {
    const myisActive = queryParams.get('isActive');
    const myText = queryParams.get('text') || '';
    setLoading(true);
    await handleStudent.downloadExcel(myisActive, myText);
    setOpenExcel(false);
    setLoading(false);
  };
  const handleDeleteStudent = () => {
    const body = {
      isActive: false,
      isDelete: true,
    };
    handleStudent
      .updateStatus(modalData.userName, body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Xóa thành công',
            description: 'Đã xóa dữ liệu học viên này khỏi hệ thống !!',
          });
          setOpen(false);
          getDataTable();
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
          });
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmitFilter = () => {
    setIsOpen(!isOpen);
    const params = new URLSearchParams(window.location.search);
    if (select2Value == 'true') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái đang hoạt động',
          field: 'isActive',
          value: true,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'true');
      } else {
        params.append('isActive', 'true');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    } else if (select2Value == 'false') {
      setDataFilter([
        {
          key: 1,
          label: 'Trạng thái không hoạt động',
          field: 'isActive',
          value: false,
        },
      ]);
      if (params.has('isActive')) {
        params.set('isActive', 'false');
      } else {
        params.append('isActive', 'false');
      }
      if (text) {
        params.set('text', text);
      } else {
        params.delete('text');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl);
    }
  };
  const handleChangeInput = (value) => {
    setText(value);
    const params = new URLSearchParams(window.location.search);
    if (params.has('text')) {
      params.set('text', value);
    } else {
      params.append('text', value);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const [dataFilter, setDataFilter] = useState([]);
  const handleDeleteFilter = (key) => {
    const updatedDataFilter = dataFilter.filter((item) => item.key !== key);
    setDataFilter(updatedDataFilter);
    const params = new URLSearchParams(window.location.search);
    if (params.has('isActive')) {
      params.delete('isActive');
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} học viên</span>
    );
  };
  return (
    <Spin spinning={loading}>
      <Modal
        title="Xóa học viên"
        open={open}
        onOk={() => handleDeleteStudent()}
        onCancel={() => hideModal()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        {modalData && (
          <p>
            Bạn đồng ý xóa dữ liệu học viên <strong>{modalData.name}</strong>{' '}
            khỏi hệ thống ?
          </p>
        )}
      </Modal>
      <Modal
        title="Tải dữ liệu học viên"
        open={openExcel}
        onOk={() => handleDownloadExcel()}
        onCancel={() => hideModalExcel()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận tải xuống dữ liệu <strong>{totalItems} học viên</strong> ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterComponent
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        setText={setText}
        text={text}
        handleChangeInput={handleChangeInput}
        showModalExcel={showModalExcel}
        dataFilter={dataFilter}
        handleDeleteFilter={handleDeleteFilter}
        handleSelect1Change={handleSelect1Change}
        select1Value={select1Value}
        searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
        dataOption={dataOption}
        select2Value={select2Value}
        setSelect2Value={setSelect2Value}
        handleSubmitFilter={handleSubmitFilter}
        placeholderInput={'Tìm kiếm theo mã học viên, tên, sđt'}
      />
      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};

export default StudentManagement;
