import React, { useState } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { handleCourse } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbDetailStudent as breadcrumbItems } from '@components/Breadcrumb';
import '../../index.scss';
import UploadCustom from '@components/UploadImage';
import CourseForm from '@components/Form/Course';
import { dateToISODate } from '@utils/Functions/formatDate';

const AddCourses = () => {
  const navigate = useNavigate();
  const initialValues = {
    gender: true,
  };
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl1, setImageUrl1] = useState<string>();
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);
  const title = 'Tạo khóa học';
  const titleButton = '';
  const routerAdd = '';

  const onFinish = (values: any) => {
    if (!imageUrl) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh lộ trình khóa học chưa được tải lên',
      });
    }
    if (!imageUrl1) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh chứng chỉ khóa học chưa được tải lên',
      });
    }
    const data = {
      name: values.name,
      totalTuitionFee: values.tuition,
      numberSessions: values.sessionsNumber,
      startDate: dateToISODate(values.date[0]),
      endDate: dateToISODate(values.date[1]),
      note: values.note,
      avatar: imageUrl1,
      imageStudyFlow: imageUrl,
      is_active: values.is_active,
      studyFlow: [
        {
          name: values.flow1.name,
          numberSessions: values.flow1.numberSessions,
          maximumDaysOff: values.flow1.maximumDaysOff,
          numberExercises: values.flow1.numberExercises,
          startDate: dateToISODate(values.flow1.date[0]),
          endDate: dateToISODate(values.flow1.date[1]),
          estimatedExamDate: values.flow1.estimatedExamDate,
          note: values.flow1.note,
        },
        {
          name: values.flow2.name,
          numberSessions: values.flow2.numberSessions,
          maximumDaysOff: values.flow2.maximumDaysOff,
          numberExercises: values.flow2.numberExercises,
          startDate: dateToISODate(values.flow2.date[0]),
          endDate: dateToISODate(values.flow2.date[1]),
          estimatedExamDate: values.flow2.estimatedExamDate,
          note: values.flow2.note,
        },
      ],
    };
    // console.log(data, 'data');
    handleCourse
      .createCourse(data)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo dữ liệu khóa học mới',
          });
          navigate('/list-courses');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <div className="main-left-block">
            <div
              className="main-left-block-title"
              onClick={() => toggleCollapse1()}
            >
              {collapsed1 ? (
                <RightOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <DownOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Thông tin khóa học
            </div>
            <CourseForm
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              collapsed1={collapsed1}
              collapsed2={collapsed2}
              toggleCollapse2={toggleCollapse2}
              isDetail={false}
            />
          </div>
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh lộ
              trình khóa học
            </h4>
            <UploadCustom
              setImageUrl={setImageUrl}
              fileList={fileList}
              setFileList={setFileList}
              classCustomAdd="upload-block-5-add"
              classCustomDetail="upload-block-5-add"
            ></UploadCustom>
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh chứng
              chỉ khóa học
            </h4>
            <UploadCustom
              setImageUrl={setImageUrl1}
              fileList={fileList1}
              setFileList={setFileList1}
              classCustomAdd="upload-block-4-add"
              classCustomDetail="upload-block-4-add"
            ></UploadCustom>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCourses;
