import UploadEndpoint from '../../api/listApi/uploadImage';

const handleUploadImage = async (file: any): Promise<any | Error> => {
  const formData = new FormData();
  formData.append('photo', file);
  try {
    const response = await UploadEndpoint.uploadImages(formData);
    if (response) {
      return response;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

const handleUploadS3 = async (
  fileName: string,
  file: any,
  folder: string,
): Promise<any | Error> => {
  try {
    const bodyKeyS3 = {
      key: folder + '/' + fileName,
    };
    console.log(bodyKeyS3, 'bodyKeyS3');
    const response = await UploadEndpoint.getKeyS3EndPoint(bodyKeyS3);
    if (response && response.status === 201) {
      try {
        const { data } = response;
        const urlEndPoint = data.url;
        console.log(data.url);
        const responS3 = await UploadEndpoint.uploadImageS3(urlEndPoint, file);
        if (responS3 && responS3.status === 200) {
          return bodyKeyS3.key;
        }
      } catch (error) {}
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export default { handleUploadImage, handleUploadS3 };
