import React, { useState } from 'react';
import { HeadProvider, Title } from 'react-head';
import { Button, Form, Input } from 'antd';
import {
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

import './login.scss';

const ForgotPassword = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [error, setError] = useState(0);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setError(1);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  type FieldType = {
    userName?: string;
    password?: string;
    remember?: string;
  };
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <div className="login-page">
      <HeadProvider>
        <div className="Home">
          <Title>30Shine - SSO</Title>
          <meta name="description" content="30Shine - SSO" />
        </div>
      </HeadProvider>

      <div className="login-form p-6 flex flex-col items-center">
        <a href={`/`}>
          <img className="logo" src="/images/ic_logo_white.png" alt="30Shine" />
        </a>

        <Form
          autoComplete="off"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="w-full mt-8 form"
        >
          <div className="my-5">
            <div className="relative">
              <Form.Item<FieldType>
                label=""
                name="userName"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  className="relative-input"
                  placeholder="Tên đăng nhập ..."
                />
                <UserOutlined className="relative-icon" />
              </Form.Item>
            </div>
          </div>
          <div className="my-5">
            <div className="relative">
              <Form.Item<FieldType>
                label=""
                name="password"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  type={isShowPassword ? 'text' : 'password'}
                  className="relative-input"
                  placeholder="Mật khẩu ..."
                />
                {isShowPassword ? (
                  <EyeInvisibleOutlined
                    onClick={handleShowPassword}
                    className="relative-icon"
                  />
                ) : (
                  <EyeOutlined
                    onClick={handleShowPassword}
                    className="relative-icon"
                  />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="">
            {error == 1 ? (
              <a className="text-red-400 text-sm" href={`/forgot-password`}>
                <p>Bạn nhập sai tên đăng nhập hoặc mật khẩu. </p>
                <strong>Quên mật khẩu?</strong>
              </a>
            ) : (
              <a className="text-slate-400 text-sm" href={`/forgot-password`}>
                Quên mật khẩu?
              </a>
            )}
          </div>
          <div>
            <input type="hidden" value={'true'} name="redirect" />
          </div>

          <Form.Item>
            <Button htmlType="submit" className="button">
              ĐĂNG NHẬP
            </Button>
          </Form.Item>
        </Form>

        <div className="mt-5 p-3">
          <p className="text-gray-500 text-sm text-justify"></p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
