import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Modal } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { handleClass } from '@services';
import { useNavigate } from 'react-router-dom';
import '@pages/index.scss';
import moment from 'moment';
import dataCity from '@configs/dataCity.json';
import { breadcrumbDetailClass as breadcrumbItems } from '@components/Breadcrumb';
import ClassForm from '@components/Form/Class';
import UploadCustom from '@components/UploadImage';
import { dateToISODate } from '@utils/Functions/formatDate';

const DetailClass = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});
  const [dataClass, setDataClass] = useState<any>();
  const [cities, setCities] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [dataSchedual, setDataSchedual] = useState([
    {
      Date: '11/08/2023',
      Description: 'Mô tả bài học',
      ISODate: '2023-08-11T17:00:00.000Z',
      Lesson: 1,
      LessonName: 'Học cắt',
    },
  ]);
  const [dataCourse, setDataCourse] = useState([]);
  const [dataTeacher, setDataTeacher] = useState([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const title = 'Chi tiết lớp học';
  const titleButton = '';
  const routerAdd = '';
  useEffect(() => {
    setCities(dataCity);
    handleClass
      .getCourseSelector()
      .then((response: any) => {
        const { data } = response;
        setDataCourse(data);
      })
      .catch((error) => {
        console.error(error);
      });
    handleClass
      .getListTeacher()
      .then((response: any) => {
        const { data } = response;
        setDataTeacher(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    if (code) {
      handleClass
        .detailClass(code)
        .then((response: any) => {
          const { data } = response;
          console.log(data, 'data');
          setDataClass(data);
          setIsActive(data.isActive);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [location.search]);
  useEffect(() => {
    if (dataClass) {
      const initValues = {
        name: dataClass.name,
        area: dataClass.area,
        userTeacher: dataClass.teacher,
        date: [moment.utc(dataClass.startDate), moment.utc(dataClass.endDate)],
        numberSessions: dataClass.numberSessions,
        course: dataClass.course[0]?.code,
        midtermExamDate: moment.utc(dataClass.midtermExamDate),
        semesterExamDate: moment.utc(dataClass.semesterExamDate),
        note: dataClass.note,
      };
      setInitialValues(initValues);
      setImageUrl(dataClass.avatar_origin);
      setDataSchedual(dataClass.schedule[0]?.dataSchedule);
      setIsActive(dataClass.isActive);
    }
  }, [dataClass]);

  const onFinish = (values: any) => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const data = {
      name: values.name,
      area: values.area,
      teacher: values.userTeacher,
      avatar: imageUrl,
      startDate: dateToISODate(values.date[0]),
      endDate: dateToISODate(values.date[1]),
      numberSessions: values.numberSessions,
      note: values.note,
      course: values.course,
      midtermExamDate: dateToISODate(values.midtermExamDate),
      semesterExamDate: dateToISODate(values.semesterExamDate),
      schedule: dataSchedual,
      isActive: isActive,
    };
    // console.log(data, 'data');
    handleClass
      .updateClass(code, data)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công cập nhật dữ liệu lớp học mới',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Lỗi trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const handleSelectCourse = (selectedValue) => {
    const selectedItem = dataCourse.find(
      (item) => item.value === selectedValue,
    );
    setInitialValues({
      course: selectedValue,
      numberSessions: selectedItem.numberSessions,
      date: [
        moment.utc(selectedItem.startDate),
        moment.utc(selectedItem.endDate),
      ],
    });
  };
  const handleAddSchedual = () => {
    const newItem = {
      Date: '11/08/2023',
      Description: 'Mô tả bài học',
      ISODate: '2023-08-11T17:00:00.000Z',
      Lesson: 1,
      LessonName: 'Học cắt',
    };

    setDataSchedual([...dataSchedual, newItem]);
  };
  const handleRemoveItemSchedual = (index) => {
    const updatedSchedual = dataSchedual.filter((_, i) => i !== index);
    setDataSchedual(updatedSchedual);
  };
  const handleOnChangeSchedual = (index, field, value) => {
    const updatedSchedual = [...dataSchedual];
    updatedSchedual[index][field] = value;
    setDataSchedual(updatedSchedual);
  };
  const handleOnChangeDateSchedual = (index, value) => {
    const updatedSchedual = [...dataSchedual];
    if (!value) {
      updatedSchedual[index]['Date'] = '';
      updatedSchedual[index]['ISODate'] = '';
      setDataSchedual(updatedSchedual);
    } else {
      updatedSchedual[index]['Date'] = moment(value).format('DD/MM/YYYY');
      updatedSchedual[index]['ISODate'] = value;
      setDataSchedual(updatedSchedual);
    }
  };
  // day anh vao list de hien thi
  useEffect(() => {
    if (dataClass) {
      if (dataClass.avatar) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: dataClass.avatar,
            thumbUrl: dataClass.avatar,
          },
        ];
        setFileList(mappedData);
      }
    }
  }, [dataClass]);

  const handleDeleteClass = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const data = {
      isActive: false,
      isDelete: true,
    };
    setIsModalOpen(false);
    handleClass
      .deleteClass(code, data)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công xóa dữ liệu lớp học',
          });
          navigate('/list-class');
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Lỗi trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Xác nhận xóa lớp học này ?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Xác nhận xóa lớp học toàn bộ dữ liệu quá trình học của học viên trong
          lớp sẽ bị xóa nhưng tài khoản sẽ vẫn được giữ. Bạn chắc chắn chứ ?
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <div className="main-left-block">
            <div
              className="main-left-block-title"
              onClick={() => toggleCollapse1()}
            >
              {collapsed1 ? (
                <RightOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <DownOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Thông tin lớp học
            </div>
            {initialValues && (
              <ClassForm
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                collapsed1={collapsed1}
                collapsed2={collapsed2}
                toggleCollapse2={toggleCollapse2}
                handleSelectCourse={handleSelectCourse}
                dataCourse={dataCourse}
                cities={cities}
                dataTeacher={dataTeacher}
                setDataSchedual={setDataSchedual}
                dataSchedual={dataSchedual}
                handleOnChangeDateSchedual={handleOnChangeDateSchedual}
                handleOnChangeSchedual={handleOnChangeSchedual}
                handleRemoveItemSchedual={handleRemoveItemSchedual}
                handleAddSchedual={handleAddSchedual}
                isDetail={true}
                isActive={isActive}
                setIsActive={setIsActive}
                handleDeleteClass={handleDeleteClass}
              />
            )}
          </div>
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh lớp học</h4>
            <UploadCustom
              setImageUrl={setImageUrl}
              fileList={fileList}
              setFileList={setFileList}
              classCustomAdd="upload-block-5-add"
              classCustomDetail="upload-block-5"
            ></UploadCustom>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailClass;
