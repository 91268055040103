import React from 'react';
import { Button, Input, Select, Form, DatePicker, Radio } from 'antd';
import { FieldType } from './type';
import {
  validateUserName,
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateAdress,
  validateFullName,
} from '@utils/Functions/validate';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';

const { Option } = Select;

const StudentForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  selectedCity,
  handleCityChange,
  cities,
  selectedDistrict,
  handleDistrictChange,
  districts,
  wards,
  isDetail,
  isDisabled,
  handleOpen,
}) => {
  return (
    <Form
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="main-add-form"
    >
      <Form.Item<FieldType>
        label="Tên đăng nhập"
        name="userName"
        rules={
          isDetail || isDisabled
            ? [{ required: true }]
            : [
                {
                  required: true,
                  validator: validateUserName,
                },
              ]
        }
      >
        <Input disabled={isDetail || isDisabled} />
      </Form.Item>
      <Form.Item<FieldType>
        label="Họ và tên học viên"
        name="name"
        rules={[
          {
            required: true,
            validator: validateFullName,
          },
        ]}
      >
        <Input placeholder="Họ và tên theo CCCD" disabled={isDisabled} />
      </Form.Item>
      <Form.Item<FieldType>
        label="Số CCCD"
        name="identificationNumber"
        rules={[{ required: true, validator: validateIdentificationNumber }]}
      >
        <Input placeholder="" disabled={isDisabled} />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>Ngày cấp/ Nơi
            cấp
          </span>
        }
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '32px',
          }}
        >
          <Form.Item<FieldType>
            label=""
            name="IdentificationDate"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập ngày cấp CCCD!',
              },
            ]}
          >
            <DatePicker
              style={{ width: 140 }}
              placeholder="dd/mm/yy"
              format="DD/MM/YYYY"
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label=""
            name="IdentificationAdress"
            style={{ marginLeft: 30, width: '100%' }}
            rules={[{ required: true, validator: validateAdress }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Nhập nơi cấp"
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<FieldType>
        label={
          <span>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>Ngày sinh
          </span>
        }
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '32px',
          }}
        >
          <Form.Item<FieldType>
            label=""
            name="DOB"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập ngày sinh học viên!',
              },
            ]}
          >
            <DatePicker
              style={{ width: 140 }}
              placeholder="dd/mm/yy"
              format="DD/MM/YYYY"
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Giới tính"
            name="gender"
            style={{ marginLeft: 30, width: '100%' }}
            rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
          >
            <Radio.Group>
              <Radio value={true} disabled={isDisabled}>
                Nam
              </Radio>
              <Radio value={false} disabled={isDisabled}>
                Nữ
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<FieldType>
        label={
          <span>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>Địa chỉ
          </span>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '32px',
          }}
        >
          <Form.Item<FieldType>
            label=""
            name="city"
            style={{ width: 'calc(50% - 10px)' }}
            rules={[{ required: true, message: 'Vui lòng chọn tỉnh thành!' }]}
          >
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              placeholder="Chọn tỉnh thành"
              style={{ width: '100%' }}
              optionFilterProp="children"
              showSearch
              disabled={isDisabled}
              filterOption={(input, option) =>
                searchIgnoreCaseAndAccent(
                  input,
                  option?.props.children?.toString() || '',
                )
              }
            >
              {cities.map((city) => (
                <Option key={city.Id} value={city.Id}>
                  {city.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<FieldType>
            label=""
            name="district"
            style={{ width: 'calc(50% - 10px)' }}
            rules={[{ required: true, message: 'Vui lòng chọn quận huyện!' }]}
          >
            <Select
              value={selectedDistrict}
              onChange={handleDistrictChange}
              placeholder="Chọn quận huyện"
              style={{ width: '100%' }}
              optionFilterProp="children"
              showSearch
              disabled={isDisabled}
              filterOption={(input, option) =>
                searchIgnoreCaseAndAccent(
                  input,
                  option?.props.children?.toString() || '',
                )
              }
            >
              {districts.map((district) => (
                <Option key={district.Id} value={district.Id}>
                  {district.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<FieldType>
        label={
          <span>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>Địa chỉ cụ
            thể
          </span>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '32px',
          }}
        >
          <Form.Item<FieldType>
            label=""
            name="wards"
            style={{ width: 'calc(50% - 10px)' }}
            rules={[{ required: true, message: 'Vui lòng chọn phường xã!' }]}
          >
            <Select
              placeholder="Chọn phường xã"
              style={{ width: '100%' }}
              optionFilterProp="children"
              showSearch
              disabled={isDisabled}
              filterOption={(input, option) =>
                searchIgnoreCaseAndAccent(
                  input,
                  option?.props.children?.toString() || '',
                )
              }
            >
              {wards.map((ward) => (
                <Option key={ward.Id} value={ward.Id}>
                  {ward.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<FieldType>
            label=""
            name="street"
            style={{ width: 'calc(50% - 10px)' }}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn số nhà, đường ..!',
              },
            ]}
          >
            <Input
              placeholder="Số nhà, tên đường ..."
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label={
          <span>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>SĐT/ Mail
          </span>
        }
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '32px',
          }}
        >
          <Form.Item<FieldType>
            label=""
            name="phone"
            rules={[
              {
                required: true,
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input
              style={{ width: 140 }}
              placeholder="* Số điện thoại"
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label=""
            name="email"
            style={{ marginLeft: 30, width: '100%' }}
            rules={[
              {
                required: true,
                validator: validateEmail,
              },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Mail"
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<FieldType>
        label="SĐT phụ huynh"
        name="parentPhone"
        rules={[
          {
            required: true,
            validator: validatePhoneNumber,
          },
        ]}
      >
        <Input placeholder="" disabled={isDisabled} />
      </Form.Item>
      <Form.Item<FieldType> label="Nguyện vọng" name="wishToJoin">
        <Input placeholder="" disabled={isDisabled} />
      </Form.Item>
      <Form.Item<FieldType> label="Ghi chú" name="note">
        <Input placeholder="" disabled={isDisabled} />
      </Form.Item>
      {!isDisabled && !isDetail && (
        <div className="button-submit-container">
          <Button type="primary" className="button-submit" htmlType="submit">
            Thêm học viên
          </Button>
        </div>
      )}
      {isDetail && (
        <>
          <Form.Item<FieldType> label="Trạng thái" name="isActive">
            <Radio.Group>
              <Radio value={true}>Hoạt động</Radio>
              <Radio value={false}>Không hoạt động</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="button-submit-container">
            <Button
              type="default"
              className="button-submit"
              onClick={handleOpen}
            >
              Reset Password
            </Button>
            <Button type="primary" className="button-submit" htmlType="submit">
              Cập nhật thông tin
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default StudentForm;
