// DashboardLayout.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  DownOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Dropdown } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './DashboardLayout.scss';

type MenuItem = Required<MenuProps>['items'][number];

type MenuItems = {
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  label: React.ReactNode;
  type?: 'group';
  to?: string;
};

import { useSelector } from 'react-redux';
interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const { user } = useSelector((state: any) => state.userName);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  // hàm kiểm tra và check đang ở menu nào
  const selectedKeys = ['1'];
  const [openKeys, setOpenKeys] = useState(['']);
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    handleResize(); // Gọi hàm handleResize khi component được render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth, 'window.innerWidth');
      if (window.innerWidth < 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function getItem(
    label: string,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    to?: string,
  ) {
    return {
      key,
      label,
      icon,
      children,
      to,
    };
  }
  // Render menu item function
  function renderMenuItem(item: MenuItems) {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {item.children.map(renderMenuItem)}
        </Menu.SubMenu>
      );
    } else if (item.to) {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.to}>{item.label}</Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      );
    }
  }
  const items: MenuItems[] = [
    // Add an image to the menu as the first item
    {
      key: 'logo',
      label: (
        <Link to={'/'}>
          <img
            src="/images/ic_logo_white.png"
            alt="Logo"
            style={{
              height: collapsed ? 30 : 50,
              display: 'block',
              margin: collapsed ? '0 0 0 -15px' : '0 auto',
            }}
          />
        </Link>
      ),
      type: 'group', // Change type to 'group'
    },

    getItem(
      'Học viên trong lớp',
      'sub3',
      <SettingOutlined />,
      undefined,
      '/student-class',
    ),
  ];
  const handleLogout = () => {
    // tiến hành xóa dữ liệu
    Cookies.remove('refreshToken');
    Cookies.remove('accessToken');
    navigate('/login');
  };
  const handleMenuOpenChange = (keys: any) => {
    setOpenKeys(keys.length > 0 ? keys : '');
  };
  const menu = (
    <Menu>
      <Menu.Item
        key={'1'}
        icon={<LoginOutlined />}
        danger={true}
        disabled={false}
        onClick={handleLogout}
      >
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="main" style={{ display: 'flex' }}>
        <div className="main-sidebar">
          <Menu
            defaultSelectedKeys={['5']}
            defaultOpenKeys={['sub2']}
            mode="inline"
            theme="dark"
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={handleMenuOpenChange}
            inlineCollapsed={collapsed}
            style={{ height: '100vh' }}
          >
            {items.map(renderMenuItem)}
          </Menu>
        </div>

        <div className={!collapsed ? 'main-wrap' : 'main-wrap-collapsed'}>
          <div className="navbar">
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            ) : (
              <MenuFoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            )}
            {user.userName && (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text">
                  Xin chào
                  <span style={{ marginLeft: 10, fontWeight: 600 }}>
                    {user.userName}
                  </span>
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
          <div className="main-content"> {children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
