import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Spin, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { handleStudent } from '@services';
import dataCity from '@configs/dataCity.json';
import moment from 'moment-timezone';
import UploadCustom from '@components/UploadImage';
import StudentForm from '@components/Form/StudentForm';
import { breadcrumbDetailStudent as breadcrumbItems } from '@components/Breadcrumb';
import type { UploadFile } from 'antd/es/upload/interface';
import { dateToISODate } from '@utils/Functions/formatDate';
import '../index.scss';

const DetailStudent = () => {
  const [initialValues, setInitialValues] = useState<any>();
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [wards, setWards] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl1, setImageUrl1] = useState<string>();
  const [imageUrl2, setImageUrl2] = useState<string>();
  const [imageUrl3, setImageUrl3] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);
  const [fileList3, setFileList3] = useState<UploadFile[]>([]);

  const title = 'Thông tin học viên';
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getData();
    }
  }, [id]);
  const getData = () => {
    handleStudent
      .GetDetailt(id)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setData(data);
          setIsLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (data) {
      const initValues = {
        userName: data.userName,
        name: data.name,
        identificationNumber: data.identificationNumber,
        IdentificationDate: moment.utc(data.IdentificationDate),
        IdentificationAdress: data.identificationAdress,
        DOB: moment.utc(data.DOB),
        gender: data.gender,
        city: data.city,
        district: data.district,
        wards: data.wards,
        street: data.street,
        phone: data.phone,
        email: data.email,
        parentPhone: data.parentPhone,
        wishToJoin: data.wishToJoin,
        note: data.note,
        isActive: data.isActive,
      };
      setInitialValues(initValues);
      setImageUrl(data.avatar_origin);
      if (data.identificationImage) {
        setImageUrl1(data.identificationImage_origin[0]);
        setImageUrl2(data.identificationImage_origin[1]);
      }
      setImageUrl3(data.isSuspended_origin);
      handleCityChange(data.city);
      handleDistrictChange(data.district);
      const city = cities.find((city) => city.Id === data.city);
      if (city) {
        const district = city.Districts.find(
          (district) => district.Id === data.district,
        );
        if (district) {
          setWards(district.Wards);
        }
      }
    }
  }, [data]);
  useEffect(() => {
    setCities(dataCity);
  }, []);
  useEffect(() => {
    if (data) {
      if (data.avatar) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.avatar,
            thumbUrl: data.avatar,
          },
        ];
        setFileList(mappedData);
      }
      if (data.identificationImage[0]) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.identificationImage[0],
            thumbUrl: data.identificationImage[0],
          },
        ];
        setFileList1(mappedData);
      }
      if (data.identificationImage[1]) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.identificationImage[1],
            thumbUrl: data.identificationImage[1],
          },
        ];
        setFileList2(mappedData);
      }
      console.log(data.isSuspended);
      if (data.isSuspended) {
        const mappedData: UploadFile[] = [
          {
            uid: '',
            lastModified: 0,
            lastModifiedDate: undefined,
            name: '',
            size: 0,
            type: 'image/jpeg',
            percent: 100,
            originFileObj: undefined,
            status: 'done',
            response: data.isSuspended,
            thumbUrl: data.isSuspended,
          },
        ];
        setFileList3(mappedData);
      }
    }
  }, [data]);
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedDistrict('');
    setWards([]);
    const city = cities.find((city) => city.Id === value);
    if (city) {
      setDistricts(city.Districts);
    }
  };

  const handleDistrictChange = (value) => {
    setSelectedDistrict(value);
    const city = cities.find((city) => city.Id === selectedCity);
    if (city) {
      const district = city.Districts.find((district) => district.Id === value);
      if (district) {
        setWards(district.Wards);
      }
    }
  };

  const onFinish = (values: any) => {
    // check xem ảnh đã được tải lên chưa
    if (!imageUrl) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh thẻ 3x4 của học viên chưa được tải lên',
      });
    }
    if (!imageUrl1 || !imageUrl2) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ảnh CCCD của học viên chưa được tải lên',
      });
    }
    // setIsLoading(true);
    const data = {
      code: id,
      information: {
        name: values.name,
        identificationNumber: values.identificationNumber,
        IdentificationDate: dateToISODate(values.IdentificationDate),
        IdentificationAdress: values.IdentificationAdress,
        DOB: dateToISODate(values.DOB),
        gender: values.gender,
        city: values.city,
        district: values.district,
        wards: values.wards,
        street: values.street,
        phone: values.phone,
        email: values.email,
        parentPhone: values.parentPhone,
        wishToJoin: values.wishToJoin,
        note: values.note,
        avatar: imageUrl,
        identificationImage: [imageUrl1, imageUrl2],
        isSuspended: imageUrl3,
      },
    };
    handleStudent
      .updateDetailt(id, data, values.isActive)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công cập nhật tài khoản học viên',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
          setIsLoading(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Xảy ra lỗi trong quá trình xử lý dữ liệu',
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleResetPassword = () => {
    // console.log(id);
    setIsLoading(true);
    handleStudent
      .resetPassword(id)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Reset Password thành công',
            description:
              'Đã resetpassword học viên thành công, vui lòng xem mật khẩu mới trong email',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
          setOpen(false);
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
          });
          setOpen(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
        setIsLoading(false);
      });
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Modal
        title="Xóa học viên"
        open={open}
        onOk={() => handleResetPassword()}
        onCancel={() => hideModal()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <p>
          Xác nhận reset mật khẩu học viên này ? Mật khẩu mới sẽ được gửi về
          Email của học viên !
        </p>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <div className="main main-add">
          <div className="main-left">
            {initialValues && (
              <StudentForm
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                selectedCity={selectedCity}
                handleCityChange={handleCityChange}
                cities={cities}
                selectedDistrict={selectedDistrict}
                handleDistrictChange={handleDistrictChange}
                districts={districts}
                wards={wards}
                isDetail={true}
                isDisabled={false}
                handleOpen={handleOpen}
              />
            )}
          </div>
          <div className="main-right">
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh chân
                dung 3x4
              </h4>
              <UploadCustom
                setImageUrl={setImageUrl}
                fileList={fileList}
                setFileList={setFileList}
                classCustomAdd="upload-block-1-add"
                classCustomDetail="upload-block-1"
              ></UploadCustom>
            </div>
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Ảnh 2 mặt
                CCCD
              </h4>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <div
                    style={{ textAlign: 'center' }}
                    className="upload-block-title"
                  >
                    Mặt trước
                  </div>
                  <UploadCustom
                    setImageUrl={setImageUrl1}
                    fileList={fileList1}
                    setFileList={setFileList1}
                    classCustomAdd="upload-block-2-add"
                    classCustomDetail="upload-block-2"
                  ></UploadCustom>
                </div>
                <div style={{ width: '50%' }}>
                  <div
                    style={{ textAlign: 'center' }}
                    className="upload-block-title"
                  >
                    Mặt sau
                  </div>
                  <UploadCustom
                    setImageUrl={setImageUrl2}
                    fileList={fileList2}
                    setFileList={setFileList2}
                    classCustomAdd="upload-block-2-add"
                    classCustomDetail="upload-block-2"
                  ></UploadCustom>
                </div>
              </div>
            </div>
            <div className="main-right-wrap-card">
              <h4 className="main-right-wrap-card-title">
                Ảnh biên bản đình chỉ học tập
              </h4>
              <UploadCustom
                setImageUrl={setImageUrl3}
                fileList={fileList3}
                setFileList={setFileList3}
                classCustomAdd="upload-block-3-add"
                classCustomDetail="upload-block-3"
              ></UploadCustom>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailStudent;
