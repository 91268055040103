import { axiosInstanceAuthorization } from '../Config/AxiosInterceptor';

const getDataExam = (id: string, typeExam: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL +
      `/api/v1/exam/${id}/deltail?exam=${typeExam}`,
  );
};
const sendDataExam = (body: any): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + `/api/v1/exam/mark-exam`,
    body,
  );
};
export default {
  getDataExam,
  sendDataExam,
};
