import { axiosInstanceClient } from '../Config/AxiosInterceptor';

const login = (userName: string, password: string): Promise<any> => {
  const body = {
    userName: userName,
    password: password,
  };
  return axiosInstanceClient.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login-admin`,
    body,
  );
};

const getArticleDetail = (language: string, link: string): Promise<any> => {
  const params = {
    language,
    link: `${link}`,
  };
  return axiosInstanceClient.get(`${process.env.REACT_APP_BASE_URL}/article`, {
    params,
  });
};

export default { login, getArticleDetail };
