import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Spin, Image as ImageAntd } from 'antd';
import TuitionFee from '@api/listApi/tuitionFee';
import type { UploadFile } from 'antd/es/upload/interface';
import moment from 'moment';
import { breadcrumbTuition } from '@components/Breadcrumb';
import TuitionForm from '@components/Form/Tuition';
// import { dateToISODate } from '@utils/Functions/formatDate';
import '../index.scss';

const TuitionPages = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [imageUrl, setImageUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [studentInfor, setStudentInfor] = useState<any>();
  const [studentInforData, setStudentInforData] = useState<any>();
  const idParam = urlSearchParams.get('id');
  const [dateSendMoney, setDateSendMoney] = useState<any>();
  const [totalMoney, setTotalMoney] = useState<any>();
  const [imgDemo, setImgDemo] = useState<string>();
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [lastPayTuition, setLastPayTuition] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const title = 'Nộp thêm học phí';
  const titleButton = '';
  const routerAdd = '';
  const scaleStep = 0.5;

  const getDataTable = async (id) => {
    try {
      setIsLoading(true);
      const response = await TuitionFee.getDataTuitionFee(id);
      setStudentInfor(response.data.data);
      setIsLoading(false);
      if (response.data.data?.tuitionData) {
        setStudentInforData(
          response.data.data && response.data.data.tuitionData?.length > 0
            ? response.data.data.tuitionData.reverse()
            : [],
        );
      }
    } catch (error) {
      notification.error({
        message: 'Thất bại',
        description: 'Vui lòng thử lại.',
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (dateSendMoney && imageUrl && totalMoney) {
      setButtonDisabled(false);
    }
  }, [dateSendMoney, imageUrl, totalMoney]);
  useEffect(() => {
    if (studentInfor) {
      setBreadcrumbItems(breadcrumbTuition(studentInfor));
    }
  }, [studentInfor]);
  useEffect(() => {
    if (studentInforData && studentInforData.length > 0) {
      if (studentInforData[0].money === 0) {
        setIsValidate(true);
        setLastPayTuition(studentInforData[0]);
      }
    }
  }, [studentInforData]);

  useEffect(() => {
    if (idParam) {
      getDataTable(idParam);
    }
  }, []);

  const onHandleSend = async () => {
    try {
      if (!imageUrl) {
        notification.error({
          message: 'Thất bại',
          description: 'Bạn chưa tải ảnh xác nhận nộp tiền.',
        });
        return;
      }
      const param = {
        userId: idParam,
        date: dateSendMoney,
        money: totalMoney,
        photo: imageUrl,
        description: '',
      };
      setIsLoading(true);
      // console.log(imageUrl, 'imageUrl');
      const response = await TuitionFee.sendDataTuitionFee(param);
      if (
        response.data?.statusCode === 200 ||
        response.data?.statusCode === 201
      ) {
        notification.success({
          message: 'Thành công',
          description: 'Nộp học phí thành công.',
        });
        setTimeout(() => {
          window.location.reload();
        }, 900);
      } else {
        notification.success({
          message: 'Thất bại',
          description: response?.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.message);
      if (
        error.response.data.message ===
        'The payment amount exceeds the tuition fee'
      ) {
        notification.error({
          message: 'Thất bại',
          description:
            'Số tiền đóng không thể vượt quá học phí của cả khóa học này',
        });
      } else {
        notification.error({
          message: 'Thất bại',
          description: 'Vui lòng thử lại.',
        });
      }
    }
  };

  return (
    <>
      <ImageAntd
        width={200}
        style={{ display: 'none' }}
        src={imgDemo}
        preview={{
          visible,
          scaleStep,
          src: imgDemo,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      <Spin
        tip="Đang xử lý dữ liệu ..."
        spinning={isLoading}
        size="large"
        style={{ display: 'flex' }}
      >
        <TitleBox
          breadcrumbItems={breadcrumbItems}
          title={title}
          titleButton={titleButton}
          routerAdd={routerAdd}
        />
        <div className="tuition-form">
          <div className="tuition-form-left">
            <TuitionForm
              studentInfor={studentInfor}
              setDateSendMoney={setDateSendMoney}
              setTotalMoney={setTotalMoney}
              setImageUrl={setImageUrl}
              fileList={fileList}
              setFileList={setFileList}
              onHandleSend={onHandleSend}
              isValidate={isValidate}
              lastPayTuition={lastPayTuition}
              buttonDisabled={buttonDisabled}
            />
          </div>
          <div className="tuition-form-right">
            <div style={{ borderBottom: 'solid 1px rgb(220,220,220)' }}>
              <h4>Lịch sử nộp tiền</h4>
            </div>
            <div
              style={{
                display: 'flex',
                borderBottom: 'solid 1px rgb(220,220,220)',
              }}
            >
              <h4 style={{ width: '250px' }}>Tổng số tiền đã nộp: </h4>
              <h4>
                {studentInfor &&
                  new Intl.NumberFormat('en-US').format(
                    studentInfor.totalTuition,
                  )}
                vnđ
              </h4>
            </div>
            {studentInforData &&
              studentInforData.map((item, index) => (
                <div style={{ display: 'flex', marginTop: '15px' }} key={index}>
                  <div
                    style={{
                      width: '20%',
                      textAlign: 'center',
                      marginTop: '-20px',
                    }}
                  >
                    <p>{`Lần ${studentInfor.tuitionData?.length - index}`}</p>
                    <div
                      style={{
                        height: '75px',
                        width: '3px',
                        backgroundColor: ' rgba(0,0,0,1)',
                        marginLeft: '49%',
                        marginTop: '-10px',
                        borderRadius: '8px',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      width: '80%',
                      borderRadius: '5px',
                      border: 'solid 1px rgba(0,0,0,0.2)',
                      borderLeft: 'solid 4px rgba(0,0,0, 0.4)',
                      height: '75px',
                      lineHeight: '7px',
                      paddingLeft: '5px',
                    }}
                  >
                    <p>{`Ngày nộp tiền: ${moment
                      .utc(item.date)
                      .format('DD/MM/yyyy')}`}</p>
                    <p>{`Số tiền nộp: ${new Intl.NumberFormat('en-US').format(
                      item.money,
                    )}`}</p>
                    <p
                      style={{ color: '#1890FF' }}
                      onClick={() => {
                        setImgDemo(item.photo);
                        setVisible(true);
                      }}
                      className="tuition-p"
                    >
                      Xem ảnh nộp tiền
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default TuitionPages;
