import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { handleClass } from '@services';
import { useNavigate } from 'react-router-dom';
import dataCity from '@configs/dataCity.json';
import moment from 'moment';
import UploadCustom from '@components/UploadImage';
import { breadcrumbAddClass as breadcrumbItems } from '@components/Breadcrumb';
import ClassForm from '@components/Form/Class';
import { dateToISODate } from '@utils/Functions/formatDate';
import '@pages/index.scss';

const AddClass = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});
  const [cities, setCities] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dataSchedual, setDataSchedual] = useState([
    {
      Date: '11/08/2023',
      Description: 'Mô tả bài học',
      ISODate: '2023-08-11T17:00:00.000Z',
      Lesson: 1,
      LessonName: 'Học cắt',
    },
  ]);
  const [dataCourse, setDataCourse] = useState([]);
  const [dataTeacher, setDataTeacher] = useState([]);
  const title = 'Tạo lớp học';
  const titleButton = '';
  const routerAdd = '';
  useEffect(() => {
    setCities(dataCity);
    handleClass
      .getCourseSelector()
      .then((response: any) => {
        const { data } = response;
        setDataCourse(data);
      })
      .catch((error) => {
        console.error(error);
      });
    handleClass
      .getListTeacher()
      .then((response: any) => {
        const { data } = response;
        setDataTeacher(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    if (code && dataCourse) {
      const selectedItem = dataCourse.find((item) => item.code === code);
      // console.log(selectedItem, 'selectedValue');
      if (selectedItem) {
        setInitialValues({
          course: selectedItem.value,
          numberSessions: selectedItem.numberSessions,
          date: [
            moment.utc(selectedItem.startDate),
            moment.utc(selectedItem.endDate),
          ],
        });
      }
    }
  }, [location.search, dataCourse]);
  const onFinish = (values: any) => {
    const data = {
      name: values.name,
      area: values.area,
      teacher: values.userTeacher,
      avatar: imageUrl,
      startDate: dateToISODate(values.date[0]),
      endDate: dateToISODate(values.date[1]),
      numberSessions: values.numberSessions,
      note: values.note,
      course: values.course,
      midtermExamDate: dateToISODate(values.midtermExamDate),
      semesterExamDate: dateToISODate(values.semesterExamDate),
      schedule: dataSchedual,
    };
    // console.log(data, 'data');
    handleClass
      .Create(data)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 201) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo dữ liệu lớp học mới',
          });
          navigate('/list-class');
        } else if (statusCode === 403) {
          notification.error({
            message: 'Lỗi',
            description: 'Lỗi trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const handleSelectCourse = (selectedValue) => {
    const selectedItem = dataCourse.find(
      (item) => item.value === selectedValue,
    );
    // console.log(selectedItem, 'selectedValue');
    setInitialValues({
      course: selectedValue,
      numberSessions: selectedItem.numberSessions,
      date: [
        moment.utc(selectedItem.startDate),
        moment.utc(selectedItem.endDate),
      ],
    });
  };
  const handleAddSchedual = () => {
    const newItem = {
      Date: '11/08/2023',
      Description: 'Mô tả bài học',
      ISODate: '2023-08-11T17:00:00.000Z',
      Lesson: 1,
      LessonName: 'Học cắt',
    };

    setDataSchedual([...dataSchedual, newItem]);
  };
  const handleRemoveItemSchedual = (index) => {
    const updatedSchedual = dataSchedual.filter((_, i) => i !== index);
    setDataSchedual(updatedSchedual);
  };
  const handleOnChangeSchedual = (index, field, value) => {
    const updatedSchedual = [...dataSchedual];
    updatedSchedual[index][field] = value;
    setDataSchedual(updatedSchedual);
  };
  const handleOnChangeDateSchedual = (index, value) => {
    const updatedSchedual = [...dataSchedual];
    if (!value) {
      updatedSchedual[index]['Date'] = '';
      updatedSchedual[index]['ISODate'] = '';
      setDataSchedual(updatedSchedual);
    } else {
      updatedSchedual[index]['Date'] = moment.utc(value).format('DD/MM/YYYY');
      updatedSchedual[index]['ISODate'] = value;
      setDataSchedual(updatedSchedual);
    }
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <div className="main-left-block">
            <div
              className="main-left-block-title"
              onClick={() => toggleCollapse1()}
            >
              {collapsed1 ? (
                <RightOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <DownOutlined
                  className="main-left-block-title-icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Thông tin lớp học
            </div>
            {initialValues && (
              <ClassForm
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                collapsed1={collapsed1}
                collapsed2={collapsed2}
                toggleCollapse2={toggleCollapse2}
                handleSelectCourse={handleSelectCourse}
                dataCourse={dataCourse}
                cities={cities}
                dataTeacher={dataTeacher}
                setDataSchedual={setDataSchedual}
                dataSchedual={dataSchedual}
                handleOnChangeDateSchedual={handleOnChangeDateSchedual}
                handleOnChangeSchedual={handleOnChangeSchedual}
                handleRemoveItemSchedual={handleRemoveItemSchedual}
                handleAddSchedual={handleAddSchedual}
                isDetail={false}
                isActive={null}
                setIsActive={null}
                handleDeleteClass={null}
              />
            )}
          </div>
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh lớp học</h4>
            <UploadCustom
              setImageUrl={setImageUrl}
              fileList={fileList}
              setFileList={setFileList}
              classCustomAdd="upload-block-5-add"
              classCustomDetail="upload-block-5-add"
            ></UploadCustom>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClass;
