import React from 'react';
import { Button, Input, Dropdown } from 'antd';
import {
  FilterOutlined,
  DownloadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import DropdownFilter from './DropdownFilter';

const FilterComponent = ({
  isOpen,
  toggleDropdown,
  setText,
  text,
  handleChangeInput,
  showModalExcel,
  dataFilter,
  handleDeleteFilter,
  handleSelect1Change,
  select1Value,
  searchIgnoreCaseAndAccent,
  dataOption,
  select2Value,
  setSelect2Value,
  handleSubmitFilter,
  placeholderInput,
}) => {
  const DropdownComponent = (
    <DropdownFilter
      handleSelect1Change={handleSelect1Change}
      select1Value={select1Value}
      searchIgnoreCaseAndAccent={searchIgnoreCaseAndAccent}
      dataOption={dataOption}
      select2Value={select2Value}
      setSelect2Value={setSelect2Value}
      toggleDropdown={toggleDropdown}
      handleSubmitFilter={handleSubmitFilter}
    />
  );
  return (
    <div className="filter">
      <div className="filter-main">
        <div className="filter-main-input">
          <Dropdown
            overlay={DropdownComponent}
            trigger={['click']}
            open={isOpen}
          >
            <Button
              type="text"
              onClick={toggleDropdown}
              className="filter-main-input-btn"
            >
              <span className="filter-main-input-btn-text">
                Thêm điều kiện lọc
              </span>
              <FilterOutlined style={{ marginLeft: 5 }} />
            </Button>
          </Dropdown>

          <Input
            placeholder={placeholderInput}
            className="filter-main-input-input"
            onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleChangeInput(e.currentTarget.value);
            }}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </div>
        <Button className="filter-main-btn" onClick={() => showModalExcel()}>
          Export Data
          <DownloadOutlined style={{ marginLeft: 5 }} />
        </Button>
      </div>
      <div className="filter-value">
        {dataFilter && (
          <>
            {dataFilter.map((item) => (
              <div key={item.key} className="filter-value-item">
                <span className="filter-value-item-span">{item.label}</span>
                <CloseOutlined
                  onClick={() => handleDeleteFilter(item.key, item)}
                  className="filter-value-item-icon"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
