import React, { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { Button, notification } from 'antd';
import QRCodeGenerator, { expiredTime } from '@configs/qrCode';
import { handleClass } from '@services/index';
import { breadcrumbAttendance as breadcrumbItems } from '@components/Breadcrumb';
import '../../index.scss';

const Attendance = () => {
  const [timeLeft, setTimeLeft] = useState(expiredTime);
  const [counting, setCounting] = useState(false);
  const [qrData, setQrData] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [data, setData] = useState<any>();
  const [expired, setExpired] = useState(false);
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get('code');
    if (codeParam) {
      handleClass
        .detailClass(codeParam)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setData(data);
          } else if (statusCode === 403) {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (data) {
      setTitle(`Điểm danh lớp ${data.name}`);
    }
  }, [data]);

  useEffect(() => {
    let countdownInterval;

    if (counting && !expired) {
      countdownInterval = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft((prevTime) => prevTime - 1);
        } else {
          clearInterval(countdownInterval);
          setCounting(false);
          setExpired(true); // Đã hết hạn
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
      setTimeLeft(expiredTime);
      setExpired(true);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [counting, timeLeft, expired]);

  const handleGetQR = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get('code');
    if (codeParam) {
      handleClass
        .getQRClass(codeParam)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setQrData(data);
            setCounting(!counting);
            setExpired(false); // Đặt lại trạng thái khi lấy QR mới
          } else if (statusCode === 403) {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      notification.error({
        message: 'Lỗi',
        description: 'Mã lớp không tồn tại !!',
      });
    }
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-qr">
        <QRCodeGenerator qrData={qrData} />
        {expired && qrData && (
          <div className="main-qr-overlayout">Mã điểm danh đã hết hạn!</div>
        )}
      </div>
      {qrData ? (
        <>
          {expired ? (
            <div className="main-qr-text">Mã điểm danh đã hết hạn!</div>
          ) : (
            <div className="main-qr-text">
              Mã điểm danh sẽ hết hạn sau: {timeLeft} giây
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" onClick={() => handleGetQR()}>
              Lấy lại mã điểm danh mới
            </Button>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" onClick={() => handleGetQR()}>
              Lấy mã điểm danh
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Attendance;
