import './index.scss';
import { Spin } from 'antd';

const LoadingPage = () => {
  return (
    <div className="loading">
      <Spin size="large" className="loading-spin" />
    </div>
  );
};

export default LoadingPage;
