import { axiosInstanceAuthorization } from '../Config/AxiosInterceptor';
import { OPageSize } from '../../configs/pageSize';

const getListClass = (
  pageIndex: string,
  isActive: string,
  title: string,
  myArea: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/class/list-class?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';
  url += myArea ? `&area=${myArea}` : '';

  return axiosInstanceAuthorization.get(url);
};

const getListClassTeacher = (
  pageIndex: string,
  isActive: string,
  title: string,
): Promise<any> => {
  let url =
    process.env.REACT_APP_BASE_URL +
    `/api/v1/class/list-class/teacher?pageIndex=${pageIndex}&pageSize=${OPageSize}`;

  url += isActive ? `&isActive=${isActive}` : '';
  url += title ? `&title=${title}` : '';

  return axiosInstanceAuthorization.get(url);
};

const listCourseSelector = (): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/courses/get-list/selecter`,
  );
};

const listTeacher = (): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/users/list-teachers`,
  );
};

const detailClass = (code: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/class/${code}/detail`,
  );
};

const getQRClass = (code: string): Promise<any> => {
  return axiosInstanceAuthorization.get(
    process.env.REACT_APP_BASE_URL + `/api/v1/student/${code}/qr-code`,
  );
};

const addStudentToClass = (body: any): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + `/api/v1/student/add-class`,
    body,
  );
};

const createClass = (body: any): Promise<any> => {
  return axiosInstanceAuthorization.post(
    process.env.REACT_APP_BASE_URL + `/api/v1/class/create`,
    body,
  );
};

const updateClass = (code: string, body: any): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/class/${code}/update`,
    body,
  );
};

const deleteClass = (code: string, body: any): Promise<any> => {
  return axiosInstanceAuthorization.put(
    process.env.REACT_APP_BASE_URL + `/api/v1/class/${code}/delete`,
    body,
  );
};

export default {
  getListClass,
  listCourseSelector,
  listTeacher,
  detailClass,
  getQRClass,
  addStudentToClass,
  createClass,
  getListClassTeacher,
  updateClass,
  deleteClass,
};
