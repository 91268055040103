import React from 'react';
import { Button, Select } from 'antd';

const DropdownFilter = ({
  handleSelect1Change,
  select1Value,
  searchIgnoreCaseAndAccent,
  dataOption,
  select2Value,
  setSelect2Value,
  toggleDropdown,
  handleSubmitFilter,
}) => {
  return (
    <div className="dropdown-component">
      <div className="dropdown-component-title">Điều kiện lọc</div>
      <div className="dropdown-component-label">Lựa chọn kiểu lọc</div>
      <Select
        style={{ width: 200, display: 'block' }}
        placeholder="Lựa chọn điều kiện"
        onChange={handleSelect1Change}
        value={select1Value}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          searchIgnoreCaseAndAccent(
            input,
            option?.props.children?.toString() || '',
          )
        }
      >
        {dataOption.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <div className="dropdown-component-label">Lựa chọn giá trị lọc</div>
      <Select
        style={{ width: 200, display: 'block' }}
        placeholder="Lựa chọn giá trị"
        disabled={!select1Value}
        value={select2Value}
        onChange={(value) => setSelect2Value(value)}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          searchIgnoreCaseAndAccent(
            input,
            option?.props.children?.toString() || '',
          )
        }
      >
        {select1Value &&
          dataOption
            .find((option) => option.value === select1Value)
            ?.select.map((subOption) => (
              <Select.Option key={subOption.value} value={subOption.value}>
                {subOption.label}
              </Select.Option>
            ))}
      </Select>
      <div className="dropdown-component-footer">
        <Button
          className="dropdown-component-footer-btn"
          onClick={() => toggleDropdown()}
        >
          Hủy
        </Button>
        <Button
          type="primary"
          className="dropdown-component-footer-btn"
          onClick={() => handleSubmitFilter()}
        >
          Xác nhận
        </Button>
      </div>
    </div>
  );
};

export default DropdownFilter;
