// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './auth/userSlice';
import loadingReducer from './component/loadingSlice';

const store = configureStore({
  reducer: {
    userName: userReducer,
    loading: loadingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
