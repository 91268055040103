import moment from 'moment';

const formatDateToDDMMYYYY = (dateString) => {
  // Chuyển đổi ngày thành đối tượng Moment
  const date = moment.utc(dateString);

  // Cấu hình múi giờ GMT+7
  date.utcOffset('+07:00');

  // Format ngày thành chuỗi theo định dạng 'dd/mm/yyyy'
  const formattedDate = date.format('DD/MM/YYYY');

  return formattedDate;
};

const dateToISODate = (dateString) => {
  const identificationDate = new Date(dateString).setHours(23, 59, 59, 999);
  const correctedTime = new Date(identificationDate);

  return correctedTime.toISOString();
};

export { formatDateToDDMMYYYY, dateToISODate };
